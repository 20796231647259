/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import React, { useEffect, useState } from "react";
import { RadioGroup, Switch } from '@headlessui/react'
import { CheckCircleIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

export const PasswordInput = ({name="password", mode, value, onChange}:{name?:string; mode?:string, value:string, onChange:React.ChangeEventHandler<HTMLInputElement>}) => {
    const [show, setShow] = useState(false)
    return <div className="relative">
        <input
            id={name}
            name={name}
            type={show ? "text" : "password"}
            autoComplete="current-password"
            className={"appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm" + (mode === "top" ? " rounded-t-md" : mode === "bottom" ? " rounded-b-md" : "")}
            placeholder="パスワード"
            value={value}
            onChange={onChange}
        />
        <div className="absolute inset-y-0 right-0 pr-1 flex items-center z-10 text-theme-800 cursor-pointer" onClick={() => setShow(!show)} >
            {show ? <EyeOffIcon className="w-6 h-6" /> : <EyeIcon className="w-6 h-6" />}
        </div>
    </div>
} 

export const FieldPlain: React.FunctionComponent<{label:string; name:string; holder?:string; complete?:string; props:KV; error?:string; span?:number;}> = ({ label, name, holder, complete = null, props={}, error=null, span = 6 }) => (<div className={`relative col-span-6 sm:col-span-${span}`}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        { error && (<span className="ml-1 text-red-500 required-dot">*</span>) }
    </label>
    <input
        type="text"
        name={name}
        id={name}
        className={"mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 " + (error ? "focus:ring-red-500 focus:border-red-500 border-red-500" : "focus:ring-indigo-500 focus:border-indigo-500  border-gray-300")}
        placeholder={holder}
        autoComplete={complete || name}
        {...props}
    />
    {error && (<p className="absolute text-sm text-red-500 -bottom-5">{error}</p>)}
</div>)

export const FieldNumber: React.FunctionComponent<{label:string; name:string; holder?:string; complete?:string; props:KV; error:string; span?:number;}> = ({ label, name, holder, complete = null, props = {}, error = null, span = 6 }) => (<div className={`relative col-span-6 sm:col-span-${span}`}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {error && (<span className="ml-1 text-red-500 required-dot">*</span>)}
    </label>
    <input
        type="number"
        name={name}
        id={name}
        className={"mt-1 block w-full shadow-sm sm:text-sm rounded-md " + (error ? "focus:ring-red-500 focus:border-red-500 border-red-500" : "focus:ring-indigo-500 focus:border-indigo-500  border-gray-300")}
        placeholder={holder}
        autoComplete={complete || name}
        {...props}
    />
    {error && (<p className="absolute text-sm text-red-500 -bottom-5">{error}</p>)}
</div>)

export const FieldArea: React.FunctionComponent<{label:string; name:string; holder?:string; detail?:string; props:KV; error?:string, span?:number; rows?:number;}> = ({ label, name, holder, detail = null, props = {}, error = null, span = 6, rows = 3 }) => (<div className={`relative col-span-6 sm:col-span-${span}`}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
    </label>
    <div className="mt-1">
        <textarea
            rows={rows}
            name={name}
            id={name}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={holder}
            {...props}
        />
    </div>
    {error && (<p className="absolute text-sm text-red-500 -bottom-5">{error}</p>)}
    <p className="mt-2 text-sm text-gray-500">
        {detail}
    </p>
</div>)

export const FieldWithMark: React.FunctionComponent<{label:string; mark:string; name:string; holder?:string; complete?:string; props:KV; error:string; span?:number;}> = ({ label, mark, name, holder, complete = null, props = {}, error = null, span = 6 }) => (<div className={`col-span-6 sm:col-span-${span}`}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {error && (<span className="ml-1 text-red-500 required-dot">*</span>)}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
        <input
            type="number"
            name={name}
            id={name}
            className={"mt-1 block w-full pr-9 shadow-sm sm:text-sm rounded-md " + (error ? "focus:ring-red-500 focus:border-red-500 border-red-500" : "focus:ring-indigo-500 focus:border-indigo-500  border-gray-300")}
            placeholder={holder}
            autoComplete={complete || name}
            {...props}
        />
        <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{mark}</span>
        </div>
    </div>
    {error && (<p className="absolute text-sm text-red-500 -bottom-5">{error}</p>)}
</div>)

export const FieldCurrency: React.FunctionComponent<{label:string; mark:string; name:string; holder:string; complete:string; span?:number;}> = ({ label, mark, name, holder, complete = null, span = 6 }) => (<div className={`col-span-6 sm:col-span-${span}`}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{mark}</span>
        </div>
        <input
            type="text"
            name={name}
            id={name}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder={holder}
            autoComplete={complete || name}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
                Currency
            </label>
            <select
                id="currency"
                name="currency"
                className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
                <option>USD</option>
                <option>CAD</option>
                <option>EUR</option>
            </select>
        </div>
    </div>
</div>)

export const FieldSelect:React.FunctionComponent<{label:string; name:string; complete?:string; options:{[key: string]: string | number}; props:KV; span?:number;}> = ({ label, name, complete = null, options, props={}, span = 6 }) => (<div className={`col-span-6 sm:col-span-${span}`}>
    <label htmlFor="country" className="block text-sm font-medium text-gray-700">{label}</label>
    <select
        id={name}
        name={name}
        autoComplete={complete || name}
        className="mt-1 block w-full py-2 pl-3 pr-6 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        {...props}
    >
        {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
    </select>
</div>
)

export const FieldSimpleCheck:React.FunctionComponent<{label:string; name:string; detail:string; props:KV; span?:number;}> = ({ label, name, detail = "", props = {}, span = 6 }) => {
    return (
        <div className={`relative col-span-6 sm:col-span-${span} flex items-center`}>
            <div className="flex items-center h-5">
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    className={`focus:ring-theme-500 h-4 w-4 text-theme-600 border-gray-300 rounded`}
                    {...props}
                    checked={props.value}
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={name} className="font-medium text-gray-700">{label}</label>
                <p className="text-gray-500">{detail}</p>
            </div>
        </div>
    )
}

export const FieldCheck:React.FunctionComponent<{label:string; name:string; detail?:string; props:KV; setFieldValue?:(arg0: string, arg1: boolean) => void; span?:number}> = ({ label, name, detail = null, props={}, setFieldValue, span = 6 }) => {
    const handleChange = () => {
        setFieldValue && setFieldValue(name, !Boolean(props.value));
    }
    return (
        <div className={`col-span-6 sm:col-span-${span}`}>
            <div className="mr-3 text-sm">
                <label htmlFor={name} className="font-medium text-gray-700">{label}</label>
                <p className="text-gray-500">{detail}</p>
            </div>
            <div className="mt-1 block w-full">
                <Switch
                    checked={props.value}
                    onChange={handleChange}
                    className={`${props.value ? 'bg-theme-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-8 rounded-full w-14 focus:outline-none focus:ring-2 ring-indigo-500 ring-offset-1`}
                >
                    <span
                        className={`${props.value ? 'translate-x-7' : 'translate-x-1'
                            } inline-block w-6 h-6 transform bg-white rounded-full`}
                    />
                </Switch>
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    className={`hidden`}
                    {...props}
                    checked={props.value}
                />
            </div>
        </div>
    )
}

/**
 * React function component for form multiple check fields
 * @param {{label:string; name:string; detail:string; options:[{name?:string, label?:string, detail?:string}]}} param0 
 * @returns 
 */
export const FieldChecks:React.FunctionComponent<{label:string; name:string; detail:string; options:[{name?:string, label?:string, detail?:string}]}> = ({ label, name = null, detail = null, options }) => (
  <fieldset>
    <div>
      <legend className="text-base font-medium text-gray-900">{label}</legend>
      <p className="text-sm text-gray-500">{detail}</p>
    </div>
    <div className="mt-4 space-y-4">
      {options.map((option) => (
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id={option.name}
              name={name || option.name}
              type={name ? "radio" : "checkbox"}
              className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300${
                !name && " rounded"
              }`}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={option.name} className="font-medium text-gray-700">
              {option.label}
            </label>
            <p className="text-gray-500">{option.detail}</p>
          </div>
        </div>
      ))}
    </div>
  </fieldset>
);

export const FieldRadio = ({ label, name, detail, options, props = {}, setFieldValue = () => { }, span = 6 }: { label: string; name: string; detail?: string; options: { name?: string, label?: string, detail?: string }[]; props?: KV; setFieldValue?: (arg0: string, arg1: string[] | number[] | string | number) => void; span?: number }) => {
    const [selected, setSelected] = useState<string>("")
    const handleChange = (e: string) => {
        setSelected(e)
        if (!setFieldValue) return null;
        setFieldValue(name, e)
    }
    useEffect(() => setSelected(options.find(option => option.name === props.value)?.name || ""), [options, props.value])
    return (<div className={`col-span-6 sm:col-span-${span} py-2`}>
        <div className="py-2">
            <label htmlFor={name} className="font-medium text-theme-700">{label}</label>
            <p className="text-gray-500">{detail}</p>
        </div>
        <RadioGroup value={selected} onChange={handleChange}>
            <div className={`grid grid-cols-${options.length} gap-2`}>
                {options.map((option) => (
                    <RadioGroup.Option
                        key={option.name}
                        value={option.name}
                        className={({ active, checked }) => `${active ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60' : ''}
                                     ${checked ? 'bg-theme-800 bg-opacity-75 text-white' : 'bg-theme-50'} relative rounded-lg shadow-md p-2 cursor-pointer flex focus:outline-none`
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center">
                                        <div className="text-sm">
                                            <RadioGroup.Label as="p" className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'}`}>
                                                {option.label}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description as="span" className={`inline ${checked ? 'text-theme-100' : 'text-gray-500'}`}>
                                                {option.detail}
                                            </RadioGroup.Description>
                                        </div>
                                    </div>
                                    {checked && (<div className="flex-shrink-0 text-white">
                                        <CheckCircleIcon className="w-6 h-6" />
                                    </div>)}
                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    </div>)
}
