import { useQuery, useLazyQuery, useMutation, makeVar, useReactiveVar, gql, DocumentNode, ApolloError, QueryLazyOptions, OperationVariables, ReactiveVar } from "@apollo/client";
import { customAlphabet } from "nanoid";
import { properties as league } from "LeagueApp/data_sources/mongodb-atlas/League/league/schema.json"
import { properties as request } from "LeagueApp/data_sources/mongodb-atlas/League/request/schema.json"
import { properties as requestView } from "LeagueApp/data_sources/mongodb-atlas/League/requestView/schema.json"
import { properties as result } from "LeagueApp/data_sources/mongodb-atlas/League/result/schema.json"
import { properties as resultView } from "LeagueApp/data_sources/mongodb-atlas/League/resultView/schema.json"
import { properties as team } from "LeagueApp/data_sources/mongodb-atlas/League/team/schema.json"

// Use appropriate specific use with direct typing of gql
// If search is specified at useData, uselazyData, adjust appropriate data query type name

const reactive:ReactiveVar<KV> = makeVar({});
/**
 * Set specific field of nested object and update reactive variable of apollo.
 * @param key Key using dot for nested object.  If empty, replace all data.
 * @param data Data to put at key
 */
export const setReactive = (key:string, data:KV):void => {
  if (!key) {
    reactive(data)
    return
  }
  let newReactive = { ...reactive }
  key.split(".").reduce((a:any, r:string, i:number, array: string[]) => {
    if (i === array.length - 1) a[r] = data
    if (!a[r]) a[r] = {}
    return a[r]
  }, newReactive)
  reactive(newReactive)
}
/**
 * Returns reactive value and setReactive function like useState.
 * @returns [reactive value, setReactive function]
 */
export const useReactive = ():[KV, (key:string, data:KV)=>void ] => [useReactiveVar(reactive), setReactive]

// collection + "Read" is used for reading.  Controlled at getFragment funciton
//const leagueRead = {...league, groups: {...league.groups, items: { ...league.groups.items, properties: {...league.groups.items.properties, assignedTeams:{ bsonType: "object", properties:team}}}}}
//const teamRead = { ...team, belongGroup: { bsonType: "object", description:"<所属>", properties: { league: { bsonType: "string", title: "所属リーグ" }, weekday: { bsonType: "int", title: "曜日" }, group: { bsonType: "string", title: "所属グループ" } } }}
export const properties:KV = {
    league: { schema: league, relation: { team: team } },
    leagueRead: { schema: league, relation: { teams: team } },
    result: { schema: result, relation: { team: team, opponent: team } },
    resultView: { schema: resultView },
    request: { schema: request, relation: { team: team } },
    requestView: { schema: requestView },
    team: { schema: team },
    teamRead: { schema: team }
}

const alphabet:string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

/**
 * Function to generate random 8 characters string using numbers and alphabets only
 */
export const nanoid = customAlphabet(alphabet, 8);

/**
 * Make GraphQL query fields as string with line break from schema.
 * @param schema 
 * @param relationSchemas 
 * @returns GraphQL query fields as string with line break
 */
export const getParams = (schema:KV, relationSchemas:KV={}):string => {
    return Object.keys(schema).map(key => {
        const type = schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text']
        if (type[0] === 'relation') return `${key} {\n${getParams(relationSchemas[type[1].split("/")[0]])}\n}`// for relation, get property keys for related object
        if (schema[key].bsonType === "object") return `${key} {\n${getParams(schema[key].properties)}\n}`
        if (schema[key].bsonType === "array" && schema[key].items.bsonType === "object") return `${key} {\n${getParams(schema[key].items.properties)}\n}`
        return key
    }).join("\n")
}

/**
 * Capitalize first letter
 * @param s String to capitalize
 * @returns Capitalized string
 */
export const capitalize:(s:string)=>string = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

/**
 * Properties for collection as Fragment
 * @param collection 
 * @param read If true, use properties of collection + "Read" 
 * @returns 
 */
export const getFragment = (collection:string, read?:boolean):DocumentNode => gql`
  fragment ${capitalize(collection)}Fields on ${capitalize(collection)} {
    ${getParams((properties[collection + (read ? "Read" : "")] || properties[collection]).schema, properties[collection].relation || {})}
  }
`;

const getAddMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($data: ${capitalize(collection)}InsertInput!) {
    addedData: insertOne${capitalize(collection)}(data: $data) {
          ...${capitalize(collection)}Fields
    }
  }
`;

const getReplaceMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($id: ${capitalize(properties[collection].schema._id.bsonType)}!, $data: ${capitalize(collection)}InsertInput!) {
    replacedData: replaceOne${capitalize(collection)}(query: { _id: $id }, data: $data) {
          ...${capitalize(collection)}Fields
    }
  }
`;

const getUpdateManyMutation = (collection:string):DocumentNode => gql`
  mutation($query: ${capitalize(collection)}QueryInput, $set: ${capitalize(collection)}UpdateInput!) {
    updateManyPayload: updateMany${capitalize(collection)}s(query: $query, set: $set) {
          matchedCount
          modifiedCount
    }
  }
`;

const getDeleteMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($id: ${capitalize(properties[collection].schema._id.bsonType)}!) {
    deletedData: deleteOne${capitalize(collection)}(query: { _id: $id }) {
          ...${capitalize(collection)}Fields
    }
  }
`;

export const useLeagueData = (completed?:()=>void):{loading:boolean, Data:[KV], error?:ApolloError} => {
    const { data, loading, error } = useQuery(gql`
      query {
        leagues {
          _id
          name
          weekday
          groups {
            groupId
            name
            teams
          }
        }
      }`,
      { onCompleted: completed }
    );
    const Data:[KV] = data?.leagues;
    return { loading, Data, error };
}

/**
 * Get function to add data.  Cache will be updated.
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useAdd = (collection:string, completed?:()=>void) => {
    const [addDataMutation, { error }] = useMutation(getAddMutation(collection), {
        // Manually save added Data into the Apollo cache so that Data queries automatically update
        update: (cache, { data: { addedData } }) => {
            cache.modify({
                fields: {
                    [collection+"s"]: (existingData = []) => [
                        ...existingData,
                        cache.writeFragment({
                            data: addedData,
                            fragment: getFragment(collection),
                        }),
                    ],
                },
            });
        },
        onCompleted: completed
    });
    /**
     * Function to add data
     * @param AddingData New data to add
     * @returns Mutation variables {addedData, error}
     */
    const addData = async (AddingData:KV): Promise<{addedData:KV, error:ApolloError|undefined}> => {
        const { data } = await addDataMutation({
            variables: {
                data: AddingData._id ? {
                    ...AddingData,
                } : {
                    _id: nanoid(),
                    ...AddingData,
                },

            },
        });
        const addedData:KV = data?.addedData || {}
        return { addedData, error };
    };

    return addData;
}

/**
 * Get function to replace data
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useReplace = (collection:string, completed?:()=>void) => {
  const [replaceDataMutation, { error }] = useMutation(getReplaceMutation(collection), { onCompleted: completed });
  /**
   * Function to replace data
   * @param id _id of document to replace
   * @param replaces New data to replace
   * @returns Mutation variables {replaceData, error}
   */
  const replaceData = async (id:string | number, replaces:KV): Promise<{replacedData:KV, error:ApolloError|undefined}> => {
    const { data } = await replaceDataMutation({
      variables: { id: id, data: replaces },
    });
    const replacedData:KV = data?.replacedData || {}
    return { replacedData, error };
  };
  return replaceData;
}

/**
 * Get function to update multiple data
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useUpdate = (collection:string, completed?:()=>void) => {
  const [updateDataMutation, { error }] = useMutation(getUpdateManyMutation(collection), { onCompleted: completed });
  /**
   * Function to update multiple data
   * @param query Query to specify updating document
   * @param updates Data to update
   * @returns Mutation variables
   */
  const updateData = async (query:KV, updates:KV): Promise<{updateManyPayload:KV, error:ApolloError|undefined}> => {
    const { data } = await updateDataMutation({
      variables: { query: query, set: updates },
    });
    const updateManyPayload:KV = data?.updateManyPayload || {}
    return { updateManyPayload, error };
  };
  return updateData;
}

/**
 * Get function to delete data.  Cache will be updated.
 * @param {string} collection Name of MongoDB collection
 * @param {()=>void} completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useDelete = (collection:string, completed?:()=>void) => {
  const [deleteDataMutation, { error }] = useMutation(getDeleteMutation(collection), { 
    update: (cache, { data: { deletedData } }) => {
        const normalizedId = cache.identify(deletedData);
        cache.evict({ id: normalizedId });
        cache.gc();
    },
    onCompleted: completed 
  });
  /**
   * Function to delete data
   * @param id _id of document to delete
   * @returns Mutation variables {deletedData, error}
   */
  const deleteData = async (id:string|number): Promise<{deletedData:KV, error:ApolloError|undefined}> => {
    const { data } = await deleteDataMutation({ variables: { id: id } });
    const deletedData:KV = data?.deletedData || {}
    return { deletedData, error };
  };
  return deleteData;
}

/**
 * Get multiple document data using GraphQL
 * @param collection Name of MongoDB collection
 * @param query Query object.  Leave undefined or give {} to get all data
 * @param sortBy Specify sorting field.  Default is _ID_ASC
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error} 
 */
export const useData = (collection:string, query:KV = {}, sortBy:string = "_ID_ASC", completed?:()=>void):{loading:boolean, Data:[KV], error?:ApolloError} => {
    const { data, loading, error } = useQuery(
        gql`
      ${getFragment(collection, true)}
      query($query: ${capitalize(collection)}QueryInput!, $sortBy: ${capitalize(collection)}SortByInput!) {
        ${collection}s(query: $query, sortBy: $sortBy) {
          ...${capitalize(collection)}Fields
        }
      }
    `,
        { variables: { query: query, sortBy: sortBy }, onCompleted: completed, fetchPolicy: "cache-and-network" }
    );
    const Data:[KV] = data?.[collection + "s"] ?? [];
    return { loading, Data, error };
}

/**
 * Get function to get multiple document data using GraphQL at runtime.
 * 
 * @param collection Name of MongoDB collection
 * @param search true if using string include search(custom resolver matchedCollection, Query type is same as QueryInput)
 * @returns Query variables { getData, loading, Data, error }
 */
export const useLazyData = (collection:string, search?:boolean, completed?:(data:KV) => void):{getData:(options?:QueryLazyOptions<OperationVariables>|undefined)=>void, loading:boolean, Data:[KV], error?:ApolloError} => {
    const [getData, { data, loading, error }] = useLazyQuery(gql`
      ${getFragment(collection, true)}
      query($query: ${capitalize(collection)}${(search && "Regex") || ""}QueryInput!) {
        ${search ? 'matched' + capitalize(collection) : collection}s(${search ? 'input' : 'query'}: $query) {
          ...${capitalize(collection)}Fields
        }
      }
      `,
        { onCompleted: completed }
    );
    const Data:[KV] = data?.[(search ? 'matched' + capitalize(collection) : collection) + "s"] ?? [];
    return { getData, loading, Data, error };
}

/**
 * Get single document data using GraphQL
 * @param collection Name of MongoDB collection
 * @param query Query object
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error} 
 */
export const useDatum = (collection:string, query:KV, completed?:()=>void): {loading:boolean, Data:KV, error?:ApolloError} => {
    const { data, loading, error } = useQuery(
        gql`
      ${getFragment(collection, true)}
      query($query: ${capitalize(collection)}QueryInput!) {
        ${collection}(query: $query) {
          ...${capitalize(collection)}Fields
        }
      }
    `,
        { variables: { query: query }, onCompleted: completed }
    );
    const Data:KV = data?.[collection] ?? {};
    return { loading, Data, error };
}

/**
 * Get summary document data using GraphQL.  Custom resolver definition(summary, SummaryInput, SummaryPayload) is required.
 * @param input Custom query object SummaryInput
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error}. Data includes {_id, count, amount}.
 */
export const useSummaryData = (input:KV = {}, completed?:()=>void):{loading:boolean, Data:[KV], error?:ApolloError} => {
    const { data, loading, error } = useQuery(
        gql`
      query($input: SummaryInput!) {
        ${input.collection}Summary(input: $input) {
          _id
          count
          amount
        }
      }
    `,
        { variables: { input: input }, onCompleted: completed }
    );
    const Data:[KV] = data?.[`${input.collection}Summary`] ?? [];
    return { loading, Data, error };
}
