import { CheckCircleIcon } from "@heroicons/react/solid";
import { FieldPlain } from "Components/uiparts";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { useRealmApp } from "RealmApp";
import { ButtonClass } from "utils";

export const FormButton = ({ completed = null }) => (
    <>
      <button type="submit" className={ButtonClass}>
        <CheckCircleIcon className="w-5 h-5" />
        送信
      </button>
    </>
  );
  
const Merge = () => {
    const app = useRealmApp();
    const navigate = useNavigate();
    if (app.currentUser?.customData?.teamId) navigate("/team")
    return (
      <>
        <div className="md:col-span-1">
          <div className="p-4">
            <h3 className="text-lg text-theme-800 font-medium leading-6">
              チーム設定フォーム
            </h3>
          </div>
        </div>
        <div className="">
          <div className="shadow sm:rounded-md">
            <Formik
              initialValues={{
                teamId: "",
                tel: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const data = await app.currentUser?.functions.getTeamDataForMerge({ _id: values.teamId, tel:values.tel})
                  console.log(data)
                  if (!data) {
                      alert("チームが登録されていないか、すでに別のメールアドレスにリンクされています")
                      return null
                  } else {
                      if (!window.confirm(`${data.name}（${data.place || ""}）でよろしいですか？`)) return null
                  }
                  const updates = {...data}
                  delete updates.__typename
                  updates._id = app.currentUser?.id || updates._id
                  updates.email = app.currentUser?.profile.email
                  updates.role = "team"
                  await app.currentUser?.functions.mergeTeam(updates)
                  await app.currentUser?.refreshCustomData();
                  setSubmitting(false);
                  navigate("/team");
                } catch (e: any) {
                  if (e.message.indexOf("Duplicate key error") > -1) {
                    alert(
                      "エラー：\n少し時間を置いて再度確定ボタンをクリックしてください"
                    );
                  } else {
                    alert("エラー：\n" + e.message);
                  }
                  setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <Form>
                  <div className="p-2 bg-white space-y-6 sm:p-6">
                    <FieldPlain
                      label="チームID"
                      name="teamId"
                      props={formik.getFieldProps("teamId")}
                    />
                    <FieldPlain
                      label="チーム代表者携帯番号(-ハイフンあり)"
                      name="tel"
                      props={formik.getFieldProps("tel")}
                    />
                </div>
                  <div className="px-4 bg-gray-50 text-right sm:px-6">
                    <FormButton />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  };
  
  export default Merge;
  