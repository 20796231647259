import React from "react";
import { useRealmApp } from "RealmApp";
import { ApolloClient, HttpLink, from, InMemoryCache, ApolloProvider } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import ApolloLinkTimeout from 'apollo-link-timeout';
import { properties } from './useCollection'
import { capitalize } from "utils"

const timeoutLink = new ApolloLinkTimeout(10000); // 10 second timeout

const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const createRealmApolloClient = (appContext) => {
  const httpLink = new HttpLink({
    uri: `https://us-west-2.aws.realm.mongodb.com/api/client/v2.0/app/${appContext.app.id}/graphql`,
    fetch: async (uri, options) => {
      if (!appContext.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      await appContext.currentUser.refreshCustomData();
      options.headers.Authorization = `Bearer ${appContext.currentUser.accessToken}`;
      return fetch(uri, options);
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // get single data from cache if exist
  const fields = Object.keys(properties).reduce((a, key) => ({ ...a, 
    [key]: {
      read(_, { args, toReference }) {
        if (args.query?._id) return toReference({
          __typename: capitalize(key),
          _id: args.query._id,
        });
      }
    }
  }), {})
  const cache = new InMemoryCache({ typePolicies: { Query: { fields: fields } } })
  const connectToDevTools =  true
  return new ApolloClient({ link: from([retryLink, errorLink, timeoutLink, httpLink]), cache, connectToDevTools });
};

export default function RealmApolloProvider({ children }) {
  const appContext = useRealmApp();
  const [client, setClient] = React.useState(createRealmApolloClient(appContext));
  React.useEffect(() => {
    setClient(createRealmApolloClient(appContext));
  }, [appContext]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}