import { useState } from 'react';
import * as Yup from 'yup';

import { properties as schema } from "LeagueApp/data_sources/mongodb-atlas/League/result/schema.json"
import relations from "LeagueApp/data_sources/mongodb-atlas/League/result/relationships.json"
import { getSchemaValues, EditBase, FormFrame, FieldBase, RelationField, CreateBase } from 'Components/edit'
import { FormikProps } from 'formik';
import TeamSearch from '../team/TeamSearch';
import options from 'contexts/options.json';

const FormFields = ({ formik }: { formik: FormikProps<any> }) => {
    const [showTeam, setShowTeam] = useState(false)
    const [showOpponent, setShowOpponent] = useState(false)

    return <FormFrame formik={formik} schema={schema}>
        <FieldBase name="date" />
        <RelationField label="チーム" onClick={e => setShowTeam(true)} item={formik.values.team} />
        <TeamSearch field="team" show={showTeam} setShow={setShowTeam} formik={formik} />
        <FieldBase name="score" />
        <FieldBase name="opponentScore" />
        <RelationField label="相手チーム" onClick={e => setShowOpponent(true)} item={formik.values.opponent} />
        <TeamSearch field="opponent" show={showOpponent} setShow={setShowOpponent} formik={formik} />
        <FieldBase name="status" option={{"":"未提出", ...options.status}} />
        <FieldBase name="modifiedAt" />
        <FieldBase name="remarks" />
    </FormFrame>
};

const validationSchema = Yup.object({
})

const writePrepare = (updates: KV) => {
}

const afterEdit = async (updates:KV, mongo?:Realm.Services.MongoDB) => {
    console.log(updates)
    if (updates.status === "cancel") {
        await mongo?.db("League").collection("request").updateMany({ date: new Date(updates.date), team: { $in: [updates.team.link, updates.opponent.link] } }, { $set: { status: "cancel" } })
    }
}

export const Create = () => {
    return (
        <CreateBase
            title="試合結果情報"
            values={getSchemaValues(schema)}
            collection="result"
            relations={relations}
            location="/admin/result"
            Fields={FormFields}
            writePrepare={writePrepare}
            afterEdit={afterEdit}
            validation={validationSchema}
        />
    );
};

export const Edit = () => {
    return (
        <EditBase
            title="試合結果情報"
            values={getSchemaValues(schema)}
            collection="result"
            relations={relations}
            location="/admin/result"
            Fields={FormFields}
            afterEdit={afterEdit}
            writePrepare={writePrepare}
            validation={validationSchema}
        />
    );
};
export default Edit;
