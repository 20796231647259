import {
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import Team from "pages/team/index";
import MergeTeam from "pages/team/merge";
import {
  Create as CreateTeam,
  Edit as EditTeam 
} from "pages/admin/team/edit";
import Request from "pages/team/request";
import Result from "pages/team/result";
import Received from "pages/team/received";
import TeamList from "pages/admin/team/list";
import LeagueList from "pages/admin/league/list";
import RequestList from "pages/admin/request/list";
import {
  Create as CreateRequest,
  Edit as EditRequest
} from "pages/admin/request/edit";
import Process from "pages/admin/process/main";
import ResultList from "pages/admin/result/list";
import { 
  Create as CreateResult,
  Edit as EditResult
} from "pages/admin/result/edit";
import {
  Create as CreateLeague,
  Edit as EditLeague,
} from "pages/admin/league/edit";
import { RequireLoggedInUser, useRealmApp } from "RealmApp";
import { ButtonClass } from "utils";
import Account from "./Account";
import { useEffect } from "react";
import Header from "./Header";
import AppContextProvider from "contexts/AppContext";
import RealmApolloProvider from "graphql/RealmApolloProvider";
import Verify from "./Verify";

const AdminError = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <p>
        このページは管理者専用です。
        <br />
        管理者のIDでログインしてください。
      </p>
      <button className={ButtonClass} onClick={() => navigate(-1)}>
        戻る
      </button>
    </div>
  );
};

const Top = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/team')
  }, [navigate])
  return <div></div>
}

const Router = () => {
  let app = useRealmApp();
//  const isAdmin = Boolean(app.currentUser?.customData?.role === "admin")
//  const navigate = useNavigate()
//  const popstate = () => {
//    navigate(1)
//  }
//  useEffect(() => {
//    window.addEventListener("popstate", popstate)
//    return () => window.removeEventListener("popstate", popstate)
//  })


  return (
    <Routes>
      <Route path="/" element={<Top/>} />
      <Route path="/verify" element={<div className="w-full h-full max-w-screen-sm text-base bg-white grid grid-rows-frame">
          <Header />
          <div className="relative overflow-y-scroll">
            <Verify />
          </div>
          <div className="bg-theme-800 px-2 py-1 text-xs text-white flex justify-between">
            <div className="hidden xs:block">Powered by MongoDB</div>
            <div>Developed by ケイ・インテリジェンス</div>
          </div>
        </div>
      } />
      <Route path="team" element={
        <div className="w-full h-full max-w-screen-sm text-base bg-white grid grid-rows-frame">
          <Header />
          <div className="relative overflow-y-scroll">
            <RequireLoggedInUser><RealmApolloProvider><AppContextProvider>
              <Outlet />
            </AppContextProvider></RealmApolloProvider></RequireLoggedInUser>
          </div>
          <div className="bg-theme-800 px-2 py-1 text-xs text-white flex justify-between">
            <div className="hidden xs:block">Powered by MongoDB</div>
            <div>Developed by ケイ・インテリジェンス</div>
          </div>
        </div>
      }>
        <Route path="" element={<Team />} />
        <Route path="merge" element={<MergeTeam />} />
        <Route path="request" element={<Request />} />
        <Route path="request/:id" element={<Request />} />
        <Route path="result" element={<Result />} />
        <Route path="receiced/:param" element={<Received />} />
        <Route path="account" element={<Account />} />
      </Route>
      <Route path="admin" element={
        <div className="w-full h-full text-base bg-white grid grid-rows-frame">
          <Header />
          <div className="relative overflow-y-scroll">
            <RequireLoggedInUser><RealmApolloProvider><AppContextProvider>
              {app.currentUser?.customData?.role === "admin" ? <Outlet /> : <AdminError />}
            </AppContextProvider></RealmApolloProvider></RequireLoggedInUser>
          </div>
          <div className="bg-theme-800 px-2 py-1 text-xs text-white flex justify-between">
            <div>Powered by MongoDB</div>
            <div>Developed by ケイ・インテリジェンス</div>
          </div>
        </div>
      }>
        <Route path="league" element={<Outlet />} >
          <Route path="" element={<LeagueList />} />
          <Route path="create" element={<CreateLeague />} />
          <Route path="edit/:id" element={<EditLeague />} />
        </Route>
        <Route path="team" element={<Outlet />} >
          <Route path="" element={<TeamList />} />
          <Route path="create" element={<CreateTeam />} />
          <Route path="edit/:id" element={<EditTeam />} />
        </Route>
        <Route path="request" element={<Outlet />}>
          <Route path="" element={<RequestList />} />
          <Route path="create" element={<CreateRequest />} />
          <Route path="edit/:id" element={<EditRequest />} />
        </Route>
        <Route path="result" element={<Outlet />}>
          <Route path="" element={<ResultList />} />
          <Route path="create" element={<CreateResult />} />
          <Route path="edit/:id" element={<EditResult />} />
        </Route>
        <Route path="process" element={<Outlet />}>
          <Route path="" element={<Process />} />
        </Route>
        <Route path="error" element={<AdminError />} />
      </Route>
    </Routes>
  );
};

export default Router;
