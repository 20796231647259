import { ReadableWebToNodeStream } from "readable-web-to-node-stream";
import ExcelJS from 'exceljs'
import { Stream } from "stream";
import { getCellByName } from "./main";
import { isSecondLast, lastDate } from "contexts/AppContext";

const getWinLoseMark = (result: KV) => (result.score || 0) > (result.opponentScore || 0) ? "○" :  (result.score || 0) < (result.opponentScore || 0) ? "●" : "△"

const compareStrings = (a:string, b:string) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;    
}

/**
 * Write result summary to Excel file.
 * Read from database using Realm find
 * Set cell name "score*" somewhere on a column(<20) including score.
 * Set cell name "teamid1*", "teamid2" somewhere on a column(<20) including teamid.  Other values should not exist on these columns
 * @param {File} file Excel file to write result data. 
 * @param {Realm.Services.MongoDB} mongo MongoDB client. 
 * @param {function(string, boolean?, boolean?):void} setMessages Message dispatcher. 
 * @returns {void}
 */
export const writeSummaryFile = async (file: File, mongo: Realm.Services.MongoDB, setMessages: (message: string, error?: boolean, add?: boolean) => void) => {

    const stream = new ReadableWebToNodeStream(file.stream());
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(stream as unknown as Stream)

    // Find teamid, ranking and score cell for each sheet
    let teamidcols: { [key: number]: number } = {}
    let rankingcols: { [key: number]: number } = {}
    workbook.eachSheet((worksheet, sheetId) => {
        const [teamidcell, rankingcell] = [getCellByName(worksheet, 'teamid'), getCellByName(worksheet, 'ranking')]
        if (!teamidcell || !rankingcell) {
            setMessages(`${worksheet.name}でチームまたはランキングのセルに名前がありません`, true)
            return null
        }
        teamidcols[sheetId] = teamidcell.fullAddress.col
        rankingcols[sheetId] = rankingcell.fullAddress.col
    })

    let results: KV[] = []
    let leagues: KV[] = []

    try {
        results = await mongo.db("League").collection("result").find({ status: { $in: ["confirmed", "single", "opponent", "default"] } }, { projection: { _id: 0, team: 1, score: 1, opponent: 1, opponentScore: 1 }, sort: { team: 1 } })
        leagues = [...await mongo.db("League").collection("league").find({}, { projection: { teams: 1 }, sort: { _id: 1 } })]
    } catch (e) {
        setMessages("データベースからデータの読込中にエラーが発生しました", true)
        return null
    }

    // Each league
    for (const league of leagues) {
        if (!league.teams) continue
        let activeSheetId: number | undefined
        let teamResults = (league.teams as number[]).map((teamId) => ({ teamId: Number(teamId), row: 0, rank: 0, name: "", place: "", num: 0, win: 0, lose: 0, draw: 0, winScore: 0, totalLost: 0 }))
        let sheetError = false
        workbook.eachSheet((worksheet, sheetId) => {
            worksheet.eachRow(row => {
                const cellTeamid = Number(row.getCell(teamidcols[sheetId]).value)//nan is falsey
                if (league.teams.includes(cellTeamid)) {
                    if (!activeSheetId) activeSheetId = sheetId
                    else if (activeSheetId !== sheetId) {
                        sheetError = true
                        setMessages(`グループ内のチームが別シートに位置しています リーグ:${league._id} チームID:${cellTeamid}`, true, true)
                    }
                    const teamResult = teamResults.find(teamResult => teamResult.teamId === cellTeamid)
                    if (teamResult) {
                        teamResult.row = row.number
                        teamResult.name = row.getCell(teamidcols[sheetId] + 1).value?.toString() || ""
                        teamResult.place = row.getCell(teamidcols[sheetId] + 2).value?.toString() || ""
                    }
                }
            })
        })
        if (sheetError || !activeSheetId) continue // If group is not found on workbook, activeSheetId is undefined
        // Sheet matrix order
        const teamRows = teamResults.map(teamResult => teamResult.row).sort((a, b) => a - b)
        if (teamRows.length !== teamRows[teamRows.length - 1] - teamRows[0] + 1) {
            setMessages(`グループ内のチームが分散しています リーグ:${league._id}`, true, true)
            continue
        }
        // Each team for the league
        for (const teamId of league.teams as number[]) {
            let teamResult = teamResults.find(teamResult => teamResult.teamId === teamId) || teamResults[0]
            // Each result for the team
            for (const result of results.filter(result => result.team === teamId)) {
                // Write to matrix
                const opponentCol = (teamResults.find(teamResult => teamResult.teamId === result.opponent) || teamResults[0]).row - teamRows[0]
                workbook.getWorksheet(activeSheetId).getCell(teamResult.row, teamidcols[activeSheetId] + 3 + opponentCol).value = `${result.score || 0}${getWinLoseMark(result)}${result.opponentScore || 0}`
                // Summarize win/lose
                teamResult.num++;
                teamResult.totalLost += result.opponentScore || 0;
                if ((result.score || 0) > (result.opponentScore || 0)) {
                    teamResult.win++;
                    teamResult.winScore += 2;
                } else if ((result.score || 0) < (result.opponentScore || 0)) {
                    teamResult.lose++;
                } else {
                    teamResult.draw++;
                    teamResult.winScore++;
                }
            }
        }
        // First sort and write ranking, pick same ranking
        teamResults.sort((a, b) => b.winScore - a.winScore || a.totalLost - b.totalLost)
        let sameRanks:number[][] = []
        teamResults.forEach((teamResult, i, a) => {
            if (i && a[i - 1].winScore === teamResult.winScore && a[i - 1].totalLost === teamResult.totalLost) {
                const rank = a[i - 1].rank
                teamResult.rank =  rank
                if (!sameRanks[rank]) sameRanks[rank] = [i - 1, i]
                else sameRanks[rank].push(i)
            } else {
                teamResult.rank = i + 1
            }
        })

        // Calc second ranking for same ranking
        sameRanks.forEach((sameRank, firstRank) => {
            const ids = teamResults.filter(teamResult => teamResult.rank === firstRank).map(v => v.teamId)
            const secondTeamResults = ids.map(id => ({ teamId:id, rank:0, winScore:0, totalLost:0}))

            for (const teamId of ids) {
                let teamResult = secondTeamResults.find(teamResult => teamResult.teamId === teamId) || teamResults[0]
                // Each result for the team
                for (const result of results.filter(result => result.team === teamId && ids.includes(result.opponent))) {
                    // Summarize win/lose
                    teamResult.totalLost += result.opponentScore || 0;
                    if ((result.score || 0) > (result.opponentScore || 0)) {
                        teamResult.winScore += 2;
                    } else if ((result.score || 0) < (result.opponentScore || 0)) {
                    } else {
                        teamResult.winScore++;
                    }
                }
            }
            secondTeamResults.sort((a, b) => b.winScore - a.winScore || a.totalLost - b.totalLost)
            secondTeamResults.forEach((teamResult, i, a) => {
                teamResult.rank = (i && a[i - 1].winScore === teamResult.winScore && a[i - 1].totalLost === teamResult.totalLost) ? a[i - 1].rank : i + firstRank
                // Write second ranking to first
                const index = teamResults.findIndex(v => v.teamId === teamResult.teamId)
                teamResults[index].rank = teamResult.rank
            })
        })
        
        teamResults.sort((a, b) => a.rank - b.rank || compareStrings(a.name, b.name))
        teamResults.forEach((teamResult, i, a) => {
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0]).value = teamResult.rank
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 1).value = teamResult.name
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 2).value = teamResult.place
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 3).value = teamResult.num
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 4).value = teamResult.win
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 5).value = teamResult.lose
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 6).value = teamResult.draw
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 7).value = teamResult.winScore
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 8).value = teamResult.totalLost

        })
    }
    const buff = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buff], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, file?.name.replace(/.*[/\\]/, '') || "");
    setMessages("ファイルへの書き込みが完了しました")
}

/**
 * Write result summary to Excel file.  For last process.  Ungamed card is automatically filled under rules.
 * Read from database using Realm find
 * Set cell name "score*" somewhere on a column(<20) including score.
 * Set cell name "teamid1*", "teamid2" somewhere on a column(<20) including teamid.  Other values should not exist on these columns
 * @param {File} file Excel file to write result data. 
 * @param {Realm.Services.MongoDB} mongo MongoDB client. 
 * @param {function(string, boolean?, boolean?):void} setMessages Message dispatcher. 
 * @param {boolean} writeCount Write request count result to ungamed score instead of 1-0
 * @returns {void}
 */
 export const writeLastSummaryFile = async (file: File, mongo: Realm.Services.MongoDB, setMessages: (message: string, error?: boolean, add?: boolean) => void, writeCount:boolean = false) => {

    const stream = new ReadableWebToNodeStream(file.stream());
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(stream as unknown as Stream)

    // Find teamid, ranking and score cell for each sheet
    let teamidcols: { [key: number]: number } = {}
    let rankingcols: { [key: number]: number } = {}
    workbook.eachSheet((worksheet, sheetId) => {
        const [teamidcell, rankingcell] = [getCellByName(worksheet, 'teamid'), getCellByName(worksheet, 'ranking')]
        if (!teamidcell || !rankingcell) {
            setMessages(`${worksheet.name}でチームまたはランキングのセルに名前がありません`, true)
            return null
        }
        teamidcols[sheetId] = teamidcell.fullAddress.col
        rankingcols[sheetId] = rankingcell.fullAddress.col
    })

    let requests: KV[] = [] // For LastProcess
    let results: KV[] = []
    let upcomingResults: KV[] = [] // For LastProcess
    let leagues: KV[] = []

    try {
        requests = await mongo.db("League").collection("request").find({}, { projection: { _id:0, date:1, team:1, double:1}}) // for LastProcess
        results = await mongo.db("League").collection("result").find({ status: { $in: ["confirmed", "single", "opponent", "default"] } }, { projection: { _id: 0, date: 1, team: 1, score: 1, opponent: 1, opponentScore: 1 }, sort: { team: 1 } })
        upcomingResults = await mongo.db("League").collection("result").find({ date: { $gt: new Date() }, status: null }, { projection: { _id: 0, date: 1, team: 1, opponent: 1 }, sort: { team: 1 } }) // for LastProcess.  Future games
        leagues = [...await mongo.db("League").collection("league").find({}, { projection: { teams: 1 }, sort: { _id: 1 } })]
    } catch (e) {
        setMessages("データベースからデータの読込中にエラーが発生しました", true)
        return null
    }

    // Each league
    for (const league of leagues) {
        if (!league.teams) continue
        let activeSheetId: number | undefined
        let teamResults = (league.teams as number[]).map((teamId) => ({ teamId: Number(teamId), row: 0, rank: 0, name: "", place: "", num: 0, win: 0, lose: 0, draw: 0, winScore: 0, totalLost: 0 }))
        let sheetError = false
        // Set row, name, place for teamResults
        workbook.eachSheet((worksheet, sheetId) => {
            worksheet.eachRow(row => {
                const cellTeamid = Number(row.getCell(teamidcols[sheetId]).value)//nan is falsey
                if (league.teams.includes(cellTeamid)) {
                    if (!activeSheetId) activeSheetId = sheetId
                    else if (activeSheetId !== sheetId) {
                        sheetError = true
                        setMessages(`グループ内のチームが別シートに位置しています リーグ:${league._id} チームID:${cellTeamid}`, true, true)
                    }
                    const teamResult = teamResults.find(teamResult => teamResult.teamId === cellTeamid)
                    if (teamResult) {
                        teamResult.row = row.number
                        teamResult.name = row.getCell(teamidcols[sheetId] + 1).value?.toString() || ""
                        teamResult.place = row.getCell(teamidcols[sheetId] + 2).value?.toString() || ""
                    }
                }
            })
        })
        if (sheetError || !activeSheetId) continue // If group is not found on workbook, activeSheetId is undefined
        // Sheet matrix order
        const teamRows = teamResults.map(teamResult => teamResult.row).sort((a, b) => a - b)
        if (teamRows.length !== teamRows[teamRows.length - 1] - teamRows[0] + 1) {
            setMessages(`グループ内のチームが分散しています リーグ:${league._id}`, true, true)
            continue
        }

        // For LastProcess
        const orderedTeams = teamRows.map(row => teamResults.find(teamResult => teamResult.row === row)?.teamId)
        const teamCount = orderedTeams.length
        const skipLastProcess = (isSecondLast(league._id) && new Date().getTime() < lastDate) // Before last Sunday(first groups), second groups should be skipped
        // Each team for the league
        for (const teamId of league.teams as number[]) {

            // for LastProcess
            const orderIndex = orderedTeams.indexOf(teamId)
            let lastProcessOrder = new Array(teamCount - 1).fill(0).map((v, i) => (orderIndex + i + 2 > teamCount) ? (orderIndex + i + 1 - teamCount) : (orderIndex + i + 1))
            
            let teamResult = teamResults.find(teamResult => teamResult.teamId === teamId) || teamResults[0]
            // Each result for the team
            for (const result of results.filter(result => result.team === teamId)) {
                // for LastProcess. Drop gamed opponent
                lastProcessOrder = lastProcessOrder.filter(v => orderedTeams[v] !== result.opponent)
                // Write to matrix
                const opponentCol = (teamResults.find(teamResult => teamResult.teamId === result.opponent) || teamResults[0]).row - teamRows[0]
                workbook.getWorksheet(activeSheetId).getCell(teamResult.row, teamidcols[activeSheetId] + 3 + opponentCol).value = `${result.score || 0}${getWinLoseMark(result)}${result.opponentScore || 0}`
                // Summarize win/lose
                teamResult.num++;
                teamResult.totalLost += result.opponentScore || 0;
                if ((result.score || 0) > (result.opponentScore || 0)) {
                    teamResult.win++;
                    teamResult.winScore += 2;
                } else if ((result.score || 0) < (result.opponentScore || 0)) {
                    teamResult.lose++;
                } else {
                    teamResult.draw++;
                    teamResult.winScore++;
                }
            }
            // Skip second last leagues when before first league last date
            if (skipLastProcess) continue

            
            let lastDouble = false
            // for LastProcess. Count # of requests of team.  Count 2 if double is done
            const requestCount = requests.filter(request => request.team === teamId).reduce((a, request) => {
                if (request.double && (new Date(request.date).getTime() > new Date().getTime()) && upcomingResults?.filter(result => result.team === teamId)?.length > 1) lastDouble = true
                return a + (request.double ? Math.max(results.filter(result => result.team === teamId && new Date(result.date).getTime() === new Date(request.date).getTime()).length, 1) : 1)
            }, 0)
//            console.log(teamId, requestCount)
//            console.log(lastProcessOrder)
            let gameNum = teamResult.num
            // If future game is planned, drop game
            const gameCount = 10 - upcomingResults.filter(result => result.team === teamId).length
            // for LastProcess.  For each unmatched opponent
            for (const order of lastProcessOrder) {
                const opponentId = orderedTeams[order]
                if (upcomingResults?.find(result => result.team === teamId && result.opponent === opponentId)) continue
                gameNum++;
                if (gameNum > gameCount) break
                // Make result
                let opponentLastDouble = false
                const opponentRequestCount = requests.filter(request => request.team === opponentId).reduce((a, request) => {
                    if (request.double && (new Date(request.date).getTime() > new Date().getTime()) && upcomingResults?.filter(result => result.team === opponentId)?.length > 1) opponentLastDouble = true
                    return a + (request.double ? Math.max(results.filter(result => result.team === opponentId && new Date(result.date).getTime() === new Date(request.date).getTime()).length, 1) : 1)
                }, 0)
//                console.log([requestCount, opponentRequestCount, lastDouble, opponentLastDouble])
                // leave result blank if rank may change double games would be done
                if (lastDouble) {
                        console.log(teamId)
                        if (!(opponentRequestCount + (opponentLastDouble ? 1 : 0) < requestCount  || requestCount + 1 < opponentRequestCount)) { console.log([requestCount, opponentRequestCount, opponentLastDouble]);continue } 
                } else if (opponentLastDouble) {
                    if (!(opponentRequestCount + 1 < requestCount  || requestCount < opponentRequestCount)) continue 
                }

                const result = { team: teamId, score: requestCount > opponentRequestCount ? 1 : 0, opponent: opponentId, opponentScore: requestCount < opponentRequestCount ? 1 : 0 }
                results.push(result)
                // Write result
                const opponentCol = (teamResults.find(teamResult => teamResult.teamId === opponentId) || teamResults[0]).row - teamRows[0]
                workbook.getWorksheet(activeSheetId).getCell(teamResult.row, teamidcols[activeSheetId] + 3 + opponentCol).value = writeCount ? 
                `[${requestCount || 0}${getWinLoseMark(result)}${opponentRequestCount || 0}]`
                : `${result.score || 0}${getWinLoseMark(result)}${result.opponentScore || 0}`
                // Summarize win/lose
                teamResult.num++;
                teamResult.totalLost += result.opponentScore || 0;
                if ((result.score || 0) > (result.opponentScore || 0)) {
                    teamResult.win++;
                    teamResult.winScore += 2;
                } else if ((result.score || 0) < (result.opponentScore || 0)) {
                    teamResult.lose++;
                } else {
                    teamResult.draw++;
                    teamResult.winScore++;
                }

            }
        }
        // First sort and write ranking, pick same ranking
        teamResults.sort((a, b) => b.winScore - a.winScore || a.totalLost - b.totalLost)
        let sameRanks:number[][] = []
        teamResults.forEach((teamResult, i, a) => {
            if (i && a[i - 1].winScore === teamResult.winScore && a[i - 1].totalLost === teamResult.totalLost) {
                const rank = a[i - 1].rank
                teamResult.rank =  rank
                if (!sameRanks[rank]) sameRanks[rank] = [i - 1, i]
                else sameRanks[rank].push(i)
            } else {
                teamResult.rank = i + 1
            }
        })

        // Calc second ranking for same ranking
        sameRanks.forEach((sameRank, firstRank) => {
            const ids = teamResults.filter(teamResult => teamResult.rank === firstRank).map(v => v.teamId)
            const secondTeamResults = ids.map(id => ({ teamId:id, rank:0, winScore:0, totalLost:0}))

            for (const teamId of ids) {
                let teamResult = secondTeamResults.find(teamResult => teamResult.teamId === teamId) || teamResults[0]
                // Each result for the team
                for (const result of results.filter(result => result.team === teamId && ids.includes(result.opponent))) {
                    // Summarize win/lose
                    teamResult.totalLost += result.opponentScore || 0;
                    if ((result.score || 0) > (result.opponentScore || 0)) {
                        teamResult.winScore += 2;
                    } else if ((result.score || 0) < (result.opponentScore || 0)) {
                    } else {
                        teamResult.winScore++;
                    }
                }
            }
            secondTeamResults.sort((a, b) => b.winScore - a.winScore || a.totalLost - b.totalLost)
            secondTeamResults.forEach((teamResult, i, a) => {
                teamResult.rank = (i && a[i - 1].winScore === teamResult.winScore && a[i - 1].totalLost === teamResult.totalLost) ? a[i - 1].rank : i + firstRank
                // Write second ranking to first
                const index = teamResults.findIndex(v => v.teamId === teamResult.teamId)
                teamResults[index].rank = teamResult.rank
            })
        })
        
        teamResults.sort((a, b) => a.rank - b.rank || compareStrings(a.name, b.name))
        teamResults.forEach((teamResult, i, a) => {
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0]).value = teamResult.rank
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 1).value = teamResult.name
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 2).value = teamResult.place
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 3).value = teamResult.num
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 4).value = teamResult.win
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 5).value = teamResult.lose
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 6).value = teamResult.draw
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 7).value = teamResult.winScore
            workbook.getWorksheet(activeSheetId || 0).getCell(teamRows[i], rankingcols[activeSheetId || 0] + 8).value = teamResult.totalLost

        })
    }
    const buff = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buff], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, file?.name.replace(/.*[/\\]/, '') || "");
    setMessages("ファイルへの書き込みが完了しました")
}
