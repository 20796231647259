import * as Yup from 'yup';
import { suggestive as yupLocale} from "contexts/yup-ja"

import { properties as schema } from "LeagueApp/data_sources/mongodb-atlas/League/team/schema.json"
import { getSchemaValues, EditBase, FormFrame, FieldBase, FieldGroup, CreateBase } from 'Components/edit'
import { FormikProps } from 'formik';

Yup.setLocale(yupLocale)

const FormFields = ({ formik }:{ formik:FormikProps<any>}) => (
    <FormFrame formik={formik} schema={schema}>
      <FieldBase name="_id" />
      <FieldBase name="name" />
      <FieldBase name="teamId" />
      <FieldBase name="place" />
      <FieldBase name="league" inputType="text"  />
      <FieldGroup
        name="repName"
        title="代表者名"
      >
        <FieldBase name="surname" />
        <FieldBase name="givenName" />
        <FieldBase name="surnameKana" />
        <FieldBase name="givenNameKana" />
      </FieldGroup>
      <FieldBase name="tel" />
      <FieldBase name="email" />
      <FieldBase name="remarks" />
    </FormFrame>
);

const validationSchema = Yup.object({
  name: Yup.string().required(),
  teamId: Yup.string().required(),
//  repName: Yup.object({
//    surname: Yup.string().required(),
//    surnameKana: Yup.string(),
//    givenName: Yup.string().required(),
//  }),
//  tel: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//  ).required(),
})


const afterEdit = async (updates:KV, mongo?:Realm.Services.MongoDB) => {
  await mongo?.db("League").collection("league").updateOne({ _id: updates.league }, { $addToSet: { "teams": Number(updates.teamId) } })
  await mongo?.db("League").collection("league").updateMany({ _id: { $ne: updates.league } }, { $pull: { "teams": Number(updates.teamId) } })
}

// Create only
const writePrepare = (updates: KV) => {
  updates._id = String(updates.teamId)
}

export const Create = () => {
  return (
    <CreateBase
      title="チーム情報"
      values={getSchemaValues(schema)}
      collection="team"
      location="/admin/team"
      Fields={FormFields}
      afterEdit={afterEdit}
      writePrepare={writePrepare}
      validation={validationSchema}
    />
  );
};

export const Edit = () => {
  return (
    <EditBase
      title="チーム情報"
      values={getSchemaValues(schema)}
      collection="team"
      location="/admin/team"
      Fields={FormFields}
      afterEdit={afterEdit}
      validation={validationSchema}
      userUpdate
    />
  );
};
export default Edit;
