import { Menu, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRealmApp } from "RealmApp";


const classNames = (...classes: string[]):string => {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const location = useLocation();
  const path: string = location.pathname;
  const app = useRealmApp();
  const navigate = useNavigate()
  const isAdmin = Boolean(app.currentUser?.customData?.role === "admin")
  const signout = () => {
    navigate("/team")
    app.logOut();
  }
  const getMenuClassName = (active: boolean) =>
    `${active ? "bg-gray-100" : ""} block px-4 py-2 text-sm text-gray-700`;

  const items = [
    { location:"/admin/team", title:"チーム管理" },
    { location:"/admin/league", title:"グループ管理" },
    { location:"/admin/request", title:"申込管理" },
    { location:"/admin/result", title:"結果報告管理" },
    { location:"/admin/process", title:"集計" },
  ]
  return (
    <div className="bg-theme-800 flex items-center justify-between">
      <Link to="/" className="flex items-center">
        <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="" className="w-6 h-6 mx-2" />
        <div className="py-2 text-white">関東草野球リーグ</div>
        
      </Link>
      {isAdmin && <div className="flex space-x-4">{items.map(item => <Link
            key={item.location}
            to={item.location}
            className={classNames(
                path.startsWith(item.location) ? 'bg-theme-400 text-yellow-100' : 'text-theme-100 bg-theme-600 hover:bg-theme-500 hover:text-yellow-100',
                'px-3 py-1 rounded-md text-sm font-medium'
            )}
            aria-current={path.startsWith(item.location) ? 'page' : undefined}
        >
            {item.title}
        </Link>)}
      </div>}
      { app.currentUser && <div className="flex items-center pr-2 ml-6">
        <Menu as="div" className="ml-3 relative">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-theme-600 p-1 flex text-white hover:text-theme-400 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-700 focus:ring-white">
                  <span className="sr-only">チームメニュー</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/team" className={getMenuClassName(active)}>
                        トップページ
                      </Link>
                    )}
                  </Menu.Item>
                  {isAdmin && <>{items.map(item => <Menu.Item key={item.location}>
                    {({ active }) => (
                      <Link to={item.location} className={getMenuClassName(active)}>
                        {item.title}
                      </Link>
                    )}
                  </Menu.Item>)}
                  </>}
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/team/account" className={getMenuClassName(active)}>
                        アカウント管理
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100" : ""
                        } block px-4 py-2 w-full text-left text-sm text-gray-700`}
                        onClick={signout}
                      >
                        ログアウト
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>}
    </div>
  );
};

export default Header;
