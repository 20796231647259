import { JSTHour, JSTLastSunday, JSTNewDate, JSTNextSunday, JSTWeekdayNumber } from "contexts/dateUtils";
import React, { useReducer } from "react";

// ----------------- Begin of Annual Overwrite ------------------
// Excluding Sunday
export const excludes = [
  JSTNewDate(2024,5,5).getTime(),
  JSTNewDate(2024,8,11).getTime(),
]
// Excluding Sunday for league 5 初
export const excludesSecondLast = [
  JSTNewDate(2024,9,15).getTime()
]
// First Sunday
const firstDate = JSTNewDate(2024, 4, 7).getTime()
// Last Sunday
export const lastDate = JSTNewDate(2024, 9, 15).getTime()
const lastDateSecondLast = JSTNewDate(2024, 9, 22).getTime()

// Second Last leagues
export const isSecondLast = (league:string) => ['5', '初'].includes(league.substring(0, 1))

// ----------------- End of Annual Overwrite --------------------

export const requestingTime = (league:string, now:Date = new Date()) => {
  const nextSunday = JSTNextSunday(now).getTime()
  if (nextSunday < firstDate) return false
  if (excludes.includes(nextSunday)) return false
  if (isSecondLast(league)) {
    if (nextSunday > lastDateSecondLast) return false
    if (excludesSecondLast.includes(nextSunday)) return false
  } else {
    if (nextSunday > lastDate) return false
  }
  const weekday = JSTWeekdayNumber(now)
  if (weekday === 2 && JSTHour(now) < 13) return true
  return ([0, 1, 6].includes(weekday))
//    return ([0, 1, 2, 3, 4, 5, 6].includes(weekday))//TEMP
}
export const resultingTime = (league:string, now:Date = new Date()) => {
  const lastSunday = JSTLastSunday(now).getTime()
  if (lastSunday < firstDate) return false
//  if (excludes.includes(lastSunday)) return false
  if (isSecondLast(league)) {
    if (lastSunday > lastDateSecondLast) return false
    if (excludesSecondLast.includes(lastSunday)) return false
  } else {
    if (lastSunday > lastDate) return false
  }
  const weekday = JSTWeekdayNumber(now)
  return ([0, 6].includes(weekday))
//    return ([0, 1, 2, 3, 4, 5, 6].includes(weekday))//TEMP
}

/** @type {*} state.collection={filter, pageBreak, limit, sortBy} */
const AppContext = React.createContext<{
  state: KV;
  dispatch: React.Dispatch<KV>;
}>({ state: {}, dispatch: () => null });

export const useAppContext = () => {
  const appContext = React.useContext(AppContext);
  if (!appContext)
    throw new Error(
      `You must call useAppContext() inside of a <AppProvider />`
    );
  return appContext;
};

/**
 * Provider for reducer.
 *
 * @param {{ children: JSX.Element }} { children }
 * @return {*} 
 */
const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(
    (state: KV, action: KV) => ({
      ...state,
      ...action,
    }),
    {} 
  );
//  const { loading, Data, error } = useLeagueData()//Data is undefined(not []) while loading
//  useEffect(() => {
//    if (Data) dispatch({ leagues: Data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
//  },[Data])
  return (
    <AppContext.Provider value={{ state: state, dispatch: dispatch }}>
      {children}{/*state.leagues ? children : (!loading && error ? <ErrorPage message="階級・グループ情報が読み込めません。"/> : <Loading />)*/}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
