import React, {
  Fragment,
  useState,
  useEffect,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
} from "react";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CollectionIcon,
  EyeIcon,
  PencilAltIcon,
  TrashIcon,
  PlusIcon,
  PrinterIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/solid";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { useRealmApp } from "RealmApp";
import { useLazyData, useDelete } from "graphql/useCollection";
import { sortAscend, sortDescend } from "utils";
import Loading from "Components/Loading";
import options from "contexts/options.json";
import { useAppContext } from "contexts/AppContext";

/**
 * Read nested data from DataObject
 * @param object DataObject to read property
 * @param prop Property name to read
 * @returns Property value
 */
//const getDataProp = (object: any, prop: string) =>
//  (object instanceof Object && prop in object && object[prop]) || null;

/**
 * Make styled button
 *
 * @param {{icon:any, onClick:()=>void, value:string}} { icon: Icon to show in button, onClick:Function to run when button is clicked, value: value of button }
 * @returns Button element
 */
const Button = ({
  name,
  icon,
  onClick,
  value,
}: {
  name?:string;
  icon: any;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  value: string;
}) => (
  <button
    name={name}
    type="button"
    onClick={onClick}
    className="m-px py-1 px-2 flex justify-center items-center 
bg-transparent hover:bg-theme-100 focus:ring-theme-100 focus:ring-offset-theme-200 text-theme-500 
transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 
w-8 h-8 rounded-lg"
    value={value}
  >
    {icon}
  </button>
);

/**
 * Make add item button
 *
 * @param {{onClick?:()=>void}} { onClick:Function to run when button is clicked }
 * @returns Button element
 */
const AddButton = ({ onClick }: { onClick?: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-12 h-12 rounded-3xl transition ease-in duration-200"
  >
    <PlusIcon className="w-8 h-8" />
  </button>
);

/**
 * Make input used in filter. Pressing return key calls onBlur
 * @param {{label:string, name:string, value:string, setState:React.Dispatch<React.SetStateAction<any>>, onBlur:(e:FocusEvent<HTMLInputElement>)=>void}}
 *  {label:Label for input, name:name of input, value:value of input, setState:Funciton to update value on change, onBlur:Funciton to run when focus is lost}
 * @returns Input element
 */
export const FilterInput = ({
  label,
  name,
  value,
  setState,
  onBlur,
}: {
  label: string;
  name: string;
  value: string;
  setState: React.Dispatch<React.SetStateAction<any>>;
  onBlur: (
    e: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => void;
}) => {
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onBlur(e);
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };
  return (
    <>
      {/*<label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">
        {label}
  </label>*/}
      <input
        type="text"
        name={name}
        className="px-2 py-1 border w-32 shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
        value={value}
        placeholder={label}
        onChange={onChange}
        onKeyUp={handleKeyUp}
        onBlur={onBlur}
      />
    </>
  );
};

/**
 * Make check switch used in filter.
 * @param obj Property object
 * @param obj.label Label for input
 * @param obj.name name of input
 * @param obj.state state of input
 * @param obj.setState Funciton to update value on change
 * @param obj.onBlur Funciton to run when state changed}
 * @returns Input element(Check using Switch of headlessui)
 */
export const FilterCheck = ({
  label,
  name,
  state,
  setState,
  onBlur,
}: {
  label: string;
  name: string;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  onBlur: (e: { target: { name: string; value: boolean } }) => void;
}) => {
  const onChange = (e: any) => {
    setState(!Boolean(state));
    onBlur({ target: { name: name, value: !Boolean(state) } });
  };
  return (
    <>
      <label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">
        {label}
      </label>
      <Switch
        id={name}
        name={name}
        checked={state}
        onChange={onChange}
        className={`${state ? "bg-theme-600" : "bg-gray-200"
          } relative my-auto inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 ring-indigo-500 ring-offset-1`}
      >
        <span
          className={`${state ? "translate-x-6" : "translate-x-1"
            } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
    </>
  );
};

/**
 * Make selection used in filter.
 * @param obj Property object
 * @param obj.label Label for selection
 * @param obj.name name of selection
 * @param obj.value initial value of selection
 * @param obj.optionName name of options object
 * @param obj.option options object(not required if optionName is set)
 * @param obj.setState Funciton to update value on change
 * @param obj.onBlur Funciton to run when state changed
 * @param obj.status If true, add active status to options
 * @param obj.nodefault If true, "":"ALL" option does not appear 
 * @returns Select element
 */
export const FilterSelect = ({
  label,
  name,
  value,
  optionName,
  option,
  setState,
  onChange,
  onBlur,
  status = false,
  nodefault = false
}: {
  label: string;
  name: string;
  value: string;
  optionName?: string;
  option?: { [key: string]: string };
  setState?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
  status?: boolean;
  nodefault?: boolean;
}) => {
  const actualOnChange = onChange ? onChange : (e: ChangeEvent<HTMLSelectElement>) => {
    setState && setState(e.target.value);
    onBlur && onBlur(e);
  };
  const actualOption = option ? option : optionName ? options[optionName] : {};
  return (
    <>
      {/*<label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">
        {label}
  </label>*/}
      <select
        name={name}
        className="py-1 px-2 border w-32 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={value}
        onChange={actualOnChange}
      >
        {!nodefault && <option value="">{label} - 全て</option>}
        {status && <option value="active">進行中</option>}
        {Object.keys(actualOption).map((key) => (
          <option key={key} value={key}>
            {/*!["paid", "cancel"].includes(key) && "-　"*/}
            {actualOption[key]}
          </option>
        ))}
      </select>
    </>
  );
};

/**
 * Make table cell including up/down buttons
 * @param obj Property object
 * @param obj.onClick Function to go up/down item
 * @returns Table cell including up/down buttons
 */
 export const UpDownTd = ({
  onClick,
}: {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (<>
  <td className="w-8 whitespace-nowrap">
    <Button name="up" icon={<ArrowUpIcon />} onClick={onClick} value="up" />
  </td>
  <td className="w-8 whitespace-nowrap">
    <Button name="down" icon={<ArrowDownIcon />} onClick={onClick} value="down" />
  </td>
  </>);



/**
 * Make table cell including link buttons
 * @param obj Property object
 * @param obj.location URL to link
 * @param obj.id Id of data
 * @param obj.custId Customer Id used to filter
 * @param obj.noEdit True to hide edit button
 * @param obj.onClickDelete Function to delete data
 * @param obj.onClickEye Function to show data
 * @param obj.onClickEdit Function to edit data
 * @param obj.onClickPrint Function to print data
 * @param obj.onClickCollection Function to show specific customer's data
 * @returns Table cell including link buttons
 */
export const EditLinkTd = ({
  location,
  id,
  custId,
  noEdit = false,
  onClickDelete,
  onClickEye,
  onClickEdit,
  onClickPrint,
  onClickCollection,
}: {
  location?: string;
  id: string;
  custId?: string;
  noEdit?: boolean;
  onClickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickEye?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <td className="flex flex-row-reverse">
    {onClickDelete && (<Button icon={<TrashIcon />} onClick={onClickDelete} value={id} />)}
    {onClickEye && (
      <Button icon={<EyeIcon />} onClick={onClickEye} value={id} />
    )}
    {onClickEdit ? (
      <Button icon={<PencilAltIcon />} onClick={onClickEdit} value={id} />
    ) : (
      !noEdit && (
        <Link to={`${location}/edit/${id}`}>
          <Button icon={<PencilAltIcon />} value={id} />
        </Link>
      )
    )}
    {onClickPrint && (
      <Button icon={<PrinterIcon />} onClick={onClickPrint} value={id} />
    )}
    {onClickCollection && custId && (
      <Button
        icon={<CollectionIcon />}
        onClick={onClickCollection}
        value={custId}
      />
    )}
  </td>
);

/**
 * Make Table cell showing data
 * @param obj Property object
 * @param obj.data Data to show
 * @param obj.badge Show data as badge
 * @param obj.left Show data left align(default is center align)
 * @param obj.date Data to show is date type string
 * @returns Table cell showing data
 */
export const ListItem = ({
  data,
  badge = false,
  left = false,
  date = false,
}: {
  data: string[];
  badge?: boolean;
  left?: boolean;
  date?: boolean;
}) => (
  <td className="px-2 py-1 whitespace-nowrap" data-value={data[0]}>
    {data.map((datum, i) =>
      badge ? (
        <span
          key={i}
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${!datum || datum.includes("非") || datum.includes("未") || datum.includes("不一致") ? "bg-red-100 text-red-800" : "bg-theme-100 text-theme-800"}`}
        >
          {datum || ""}
        </span>
      ) : (
        <div key={i} className={left ? "text-left" : ""}>
          {date
            ? datum
              ? new Date(datum).toLocaleDateString()
              : ""
            : datum || ""}
        </div>
      )
    )}
  </td>
);

/**
 * Make Table cell showing name including corporate name if exist
 * @param obj Property object
 * @param obj.data Data including name attributes
 * @returns Table cell showing name
 */
export const ListName = ({ data }: { data: KV[] }) => (
  <td className="px-2 py-1 whitespace-nowrap">
    {data.map((datum, i) => (
      <Fragment key={i}>
        {datum && datum.corporateName && (
          <div>{`${datum.corporateName || ""}　${datum.division || ""}`}</div>
        )}
        <div>
          {datum &&
            `${datum.surname || datum.name?.surname || ""}　${datum.givenName || datum.name?.givenName || ""
            }`}
        </div>
      </Fragment>
    ))}
  </td>
);

/**
 * Make List View JSX element of data in collection applying filter
 * @param obj Property object
 * @param obj.collection name of MongoDB collection
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to get filter JSX Element
 * @param obj.noAdd disable adding data
 * @param obj.Data Data to show derived from MongoDB
 * @param obj.Row JSX Element to draw each row
 * @param obj.Dialog Dialog to show when printing
 * @returns List view
 */
export const SortableListFrame = ({
  collection,
  location,
  heads,
  filter,
  download,
  noAdd,
  pagination,
  paginatedData,
  setPaginatedData,
  count = 0,
  Data,
  Row,
  Dialog,
}: {
  collection: string;
  location: string;
  heads: { [key: string]: string }[];
  filter?: (
    key: string,
    count: number,
    first: KV,
    last: KV,
    handleDownload: () => void
  ) => JSX.Element;
  download?: (Data: KV[]) => KV[];
  noAdd: boolean;
  pagination?: boolean;
  paginatedData?: KV[];
  setPaginatedData?:React.Dispatch<KV[]>
  count: number,
  Data: KV[];
  Row: ({
    datum,
    clickDelete,
    clickCollection,
    clickPrint,
  }: {
    datum: KV;
    clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
  }) => JSX.Element;
  Dialog?: ({
    current,
    setCurrent,
  }: {
    current: KV | null;
    setCurrent: React.Dispatch<
      React.SetStateAction<KV | null>
    >;
  }) => JSX.Element;
}) => {
  const appContext = useAppContext();
  const page = appContext.state[collection]?.page || 1
  const [limit, setLimit] = useState(appContext.state[collection]?.limit || 100)
//  const sortBy = appContext.state[collection]?.sortBy
  const [ascend, setAscend] = useState(!Boolean(appContext.state[collection]?.sortBy?.endsWith("_desc")));
  const [sortKey, setSortKey] = useState<string>(appContext.state[collection]?.sortBy?.replace(/_asc|_desc/, "") || "_id");// key for sorting
  // Data for selected cell
  const [currentDatum, setCurrentDatum] = useState<{
    [key: string]: any;
  } | null>(null);
  const collectionForDelete = collection.endsWith("View") ? collection.slice(0, -4) : collection
  const deleteData = useDelete(collectionForDelete); //, completed);
  const handleDeleteClick = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("削除してよろしいですか？")) {
        const deletingId = e.currentTarget.value
        await deleteData(deletingId);
        if (pagination && setPaginatedData) setPaginatedData(paginatedData?.filter(data => data._id !== deletingId) || [])
      }
    } catch (e: any) {
      alert("エラー：\n" + e.message);
    }
  };
  const handleListItemIDClick = (e: MouseEvent<HTMLButtonElement>) => {
    const query = {
      ...appContext.state[collection].filter,
      teamId: e.currentTarget.value,
    };
    appContext.dispatch({
      [collection]: { ...appContext.state[collection], filter: query },
    });
  };
  // Function to sort Data array
  const [sortFunction, setSortFunction] = useState({
    function: ascend ? sortAscend(sortKey) : sortDescend(sortKey),
  });
  const handleHeaderClick = (e: MouseEvent<HTMLTableCellElement>) => {
    const key = e.currentTarget.dataset.name;
    const field = heads[parseInt(e.currentTarget.dataset.value || "0")]?.field;
    console.log([sortKey, key, field, sortKey])
    if (key !== sortKey) {// change key and ascend
      setSortKey(key || "");
      console.log(["new", page, Data.length, limit])
      if (page !== 1 || Data.length === parseInt(limit)) {
        appContext.dispatch({ [collection]: { ...appContext.state[collection] || {}, page:1, pageBreak: undefined, sortBy: `${key}_asc` } })
      }
      setAscend(true);
      setSortFunction({
        function: sortAscend(field)
      });
    } else { // no key change
      setAscend(!ascend);
      if (!ascend) { //previous is descend and new is ascend
        console.log(["ascend", page, Data.length, limit])
        if (page !== 1 || Data.length === parseInt(limit)) {
          appContext.dispatch({ [collection]: { ...appContext.state[collection] || {}, page:1, pageBreak: undefined, sortBy: `${key}_asc` } })
        }
        setSortFunction({
          function: sortAscend(field)
        });
      } else { //new is descend
        console.log(["descend", page, Data.length, limit])
        if (page !== 1 || Data.length === parseInt(limit)) {
          appContext.dispatch({ [collection]: { ...appContext.state[collection] || {}, page:1, pageBreak: undefined, sortBy: `${key}_desc` } })
        }
        setSortFunction({
          function: sortDescend(field),
        });
      }
    }
  };
  const handlePageClick = (e: MouseEvent<HTMLButtonElement>) => {
    let newPage = page
    if (e.currentTarget.name === "nextPage") newPage++
    if (e.currentTarget.name === "prevPage") newPage--
    appContext.dispatch({ [collection]: { ...appContext.state[collection] || {}, page: newPage } })
  };
  const handleLimitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLimit(e.target.value)
    appContext.dispatch({ [collection]: { ...appContext.state[collection] || {}, limit: parseInt(e.target.value), page:1 } })
  };
  return (
    <>
      <div className="sticky left-0 top-0 w-full h-full z-10 pointer-events-none">
        {
          <div className="absolute left-0 right-0 top-0 px-4 flex justify-between bg-theme-100 pointer-events-auto">
            {filter ? <div className="p-2 relative flex items-center space-x-1">{
              filter("_id", Data.length, Data[0], Data.slice(-1)[0], () => handleDownload((download && download(Data)) || []))
             }</div>
              : <div />
            }
            <div className="p-2 bg-transparent flex items-center">
              <span className="flex-grow"></span>
              <div className="m-2 text-right">{count ? `${count}件` : `${Data.length}件${Data.length === limit ? "以上" : ""
                }`}</div>
              {pagination && <>表示数：<FilterSelect
                  label=""
                  name="limit"
                  value={limit}
                  optionName="limit"
                  onChange={handleLimitChange}
                  nodefault
                />
                <button
                  className={page === 1 ? "invisible" : ""}
                  name="prevPage"
                  onClick={handlePageClick}
                >
                  <ChevronLeftIcon className="w-6 h-6" />
                </button>
                {`${limit * (page - 1) + 1} - ${limit * (page - 1) + Data.length}`}
                <button
                  className={page * limit >= count ? "invisible" : ""}
                  name="nextPage"
                  onClick={handlePageClick}
                >
                  <ChevronRightIcon className="w-6 h-6" />
                </button>
              </>}
            </div>
          </div>
        }
        {!noAdd && (
          <Link
            className="absolute right-4 bottom-4 pointer-events-auto"
            to={`${location}/create`}
          >
            <AddButton />
          </Link>
        )}
      </div>
      <div className="absolute left-0 top-0 right-0 m-4 flex flex-col z-0">
        <div className="-my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-24 align-middle min-w-full flex justify-center sm:px-6 lg:px-8">
            <div className="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="divide-y divide-gray-200 text-center text-sm text-gray-500">
                <thead className="bg-theme-50">
                  <tr>
                    {heads.map((head, i) => (
                      <th
                        key={head.field + i}
                        data-name={head.field}
                        data-value={i}
                        scope="col"
                        className="p-1 font-medium tracking-wider cursor-pointer"
                        onClick={handleHeaderClick}
                      >
                        <div className="flex justify-center">
                          <span>{head.text}</span>
                          {head.field === sortKey &&
                            (ascend ? (
                              <ChevronUpIcon className="w-5 h-5" />
                            ) : (
                              <ChevronDownIcon className="w-5 h-5" />
                            ))}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {[...Data]
                    .sort((a, b) => sortFunction.function(a, b))
                    .map((datum) => (
                      <tr key={datum._id} className="text-sm text-gray-900">
                        <Row
                          datum={datum}
                          clickDelete={handleDeleteClick}
                          clickCollection={handleListItemIDClick}
                          clickPrint={(e) => setCurrentDatum(datum)}
                        />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {Dialog && <Dialog current={currentDatum} setCurrent={setCurrentDatum} />}
    </>
  );
};

/**
 * Make List View outer frame applying filter.  Tbody items must be at innnr HTML
 * @param obj Property object
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to get filter JSX Element
 * @param obj.noAdd disable adding data
 * @param obj.children Child JSX Elements
 * @returns List view
 */

export const SimpleListFrame = ({
  location,
  heads,
  noAdd,
  children,
}: {
  location?: string;
  heads: string[];
  noAdd: boolean;
  children: JSX.Element;
}) => (
  <div className="relative m-4 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 text-center text-sm text-gray-500">
            <thead className="bg-theme-50">
              <tr>
                {heads.map((head, i) => (
                  <th
                    key={i}
                    scope="col"
                    className="p-1 font-medium tracking-wider"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {!noAdd && (
      <Link to={`${location}/create`}>
        <AddButton />
      </Link>
    )}
  </div>
);

export const flatten = (
  obj:
    | KV
    | string
    | number
    | KV[]
    | string[]
    | number[]
    | undefined
    | null,
  path = ""
): KV => {
  if (!(obj instanceof Object)) return { [path.replace(/\.$/g, "")]: obj };
  return Object.keys(obj).reduce((output, key) => {
    return obj instanceof Array
      ? { ...output, ...flatten(obj[parseInt(key)], path + "[" + key + "].") }
      : { ...output, ...flatten(obj[key], path + key + ".") };
  }, {});
};

/**
 * Generating csv file using export-to-csv package
 *
 * @param {KV[]} data data to export
 */
const handleDownload = async (data: KV[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Data");
  sheet.columns = Object.keys(data[0]).map((v) => ({ header: v, key: v }));
  sheet.addRows(data);
  const buff = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buff], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "output.xlsx");
};

/**
 * Adjusts filter object.  Empty value is removed.
 *
 * @param {KV} filter Filter query dataw before adjustment
 * @return {*}  {KV} Adjusted filter
 */
const adjustFilter = (filter: KV): KV =>
  Object.keys(filter).reduce((a, r) => {
    if (Array.isArray(filter[r])) {
      if (filter[r].length < 1) return a;
      return { ...a, [r]: filter[r] };
    } else if (filter[r] instanceof Object && !(filter[r] instanceof Date)) {
      const obj = adjustFilter(filter[r]);
      if (Object.keys(obj).length < 1) return a;
      return { ...a, [r]: adjustFilter(filter[r]) };
    } else if (!filter[r]) {
      return a;
    } else {
      return { ...a, [r]: filter[r] };
    }
  }, {});

    // Kusayakyu customization. Combine league and group.  Both direct children and nested under team object.
    const adjustFilterCustom = (origFilter: KV, pagination:boolean = false) => {
      let filter = { ...origFilter }
      // Kusayakyu customization. Combine league and group.  Both direct children and nested under team object.
      if (filter.league || filter.group) {
        const leagues = filter.league ? 
        (filter.group ? [filter.group] : Object.keys(options.group)).map(v => filter.league+v)
          :  Object.keys(options.league).map(v => v + filter.group)
        if (pagination) filter.league = { $in: leagues }
        else {
          filter.league_in = leagues
          if (filter.league) delete filter.league
        }
        if (filter.group) delete filter.group 
      }
      if (filter.team?.league || filter.team?.group) {
        const leagues = filter.team?.league ? 
          (filter.team?.group ? [filter.team.group] : Object.keys(options.group)).map(v => filter.team.league+v)
          :  Object.keys(options.league).map(v => v + filter.team.group)
        if (pagination) filter.team.league = { $in: leagues }
        else {
          filter.team.league_in = leagues
          if (filter.team.league) delete filter.team.league
        }
        if (filter.team.group) delete filter.team.group 
      }
      if (pagination) {
        if (filter.role === "na") filter.role = { $exists: false }
        if (filter.name) filter.name = { $regex: filter.name }
        if (filter.tel) filter.tel = { $regex: filter.tel }
        if (filter.email) filter.email = { $regex: filter.email }
        if (filter.team && filter.team instanceof Object) {
          Object.keys(filter.team).forEach(key => filter[`team.${key}`] = filter.team[key])
          delete filter.team
        }
      }

      return filter
    }
  
/**
 * Make List View JSX element of data in collection applying filter.  Mainly in charge of getting data from MongoDB.
 * @param obj Property object
 * @param obj.collection name of MongoDB collection
 * @param obj.search Use text include search (without apollo)
 * @param {string?} obj.sort sorting parameter of MongoDB Realm.  Context state has priority.
 * @param obj.limit limiting parameter of MongoDB Realm  Context state has priority.
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to make filter JSX Element
 * @param obj.download Excel or CSV base data for download
 * @param obj.row JSX Element to draw each row
 * @param obj.dialog Dialog to show when printing
 * @param obj.user Keys using user(relation)
 * @param obj.noAdd disable adding data
 * @returns List view
 */
export const DataList = ({
  collection,
  search = false,
  sort = "_id_asc",
  limit = 100,
  location,
  relations,
  heads,
  filter,
  download,
  row,
  dialog,
  user,
  noAdd = false,
  pagination = false
}: {
  collection: string;
  search?: boolean;
  sort?: string;
  limit?: number;
  location: string;
  relations?:KV;
  heads: { [key: string]: string }[];
  filter?: (
    key: string,
    count: number,
    first: KV,
    last: KV,
    handleDownload: () => void
  ) => JSX.Element;
  download?: (Data: KV[]) => KV[];
  row: ({
    datum,
    clickDelete,
    clickCollection,
    clickPrint,
  }: {
    datum: KV;
    clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
  }) => JSX.Element;
  dialog?: ({
    current,
    setCurrent,
  }: {
    current: KV | null;
    setCurrent: React.Dispatch<
      React.SetStateAction<{ [key: string]: any | null }>
    >;
  }) => JSX.Element;
  user?: [key: string];
  noAdd?: boolean;
  pagination?: boolean;
}) => {
  const app = useRealmApp();
  const appContext = useAppContext()
  const { getData, loading, Data, error } = useLazyData(collection, search);
  const [paginatedData, setPaginatedData] = useState<KV[]>([])
  const [paginateLoading, setPaginateLoading] = useState(false)
  const [count, setCount] = useState<number>(0)
  useEffect(() => {
    const states: KV = appContext.state[collection] || {};
    const origFilter = states?.filter || {};
    let filter = adjustFilterCustom(adjustFilter(origFilter), pagination)
    const actualQuery =  search ? 
      { query: filter || {} }
       : filter || {}
    async function getCount() {
      const data = await app.currentUser?.functions.countDocuments({
        collection: collection,
        query: actualQuery,
      })
      setCount(data)
    }
    if (pagination) getCount()
  }, [app.currentUser?.functions, appContext.state, collection, pagination, search]);
  useEffect(() => {
    // Read data througbh function using aggregate
    async function getPaginatedData() {
      /*console.log({
        collection: collection,
        query: actualQuery,
        sortBy: states.sortBy || sort,
        skip: states.page ? actualLimit * (states.page - 1) : 0,
        limit: actualLimit, 
        relations: relations ? Object.keys(relations).map(key => ({
          from: relations[key].ref.split("/").slice(-1)[0],
          localField: relations[key].source_key,
          foreignField: relations[key].foreign_key,
          as: relations[key].source_key
        })) : undefined
      })*/
      setPaginateLoading(true)
      const data = await app.currentUser?.functions.aggregateDocuments({
        collection: collection,
        query: actualQuery,
        sortBy: states.sortBy || sort,
        skip: states.page ? actualLimit * (states.page - 1) : 0,
        limit: actualLimit, 
        relations: relations ? Object.keys(relations).map(key => ({
          from: relations[key].ref.split("/").slice(-1)[0],
          localField: relations[key].source_key,
          foreignField: relations[key].foreign_key,
          as: relations[key].source_key
        })) : undefined
      })
      setPaginateLoading(false)
      setPaginatedData(data)
    }
    const states:KV = appContext.state[collection] || {};
    const origFilter = states?.filter || {};
    let filter = adjustFilterCustom(adjustFilter(origFilter), pagination)
    const actualLimit = states.limit || limit
    const actualQuery = search ? 
      { query: filter || {}, pageBreak: states.pageBreak, limit: actualLimit, sortBy: states.sortBy || sort }
       : filter || {} 
    if (pagination) {
      getPaginatedData()
    } else {
      getData({ variables: {
        sortBy: states.sortBy?.toUpperCase() || sort.toUpperCase(),// UpperCase to pass to GraphQL
        limit: actualLimit, 
        query: actualQuery
      } });
    }
//    getData({ variables: { query: search ? { query: filter || {}, pageBreak:states.pageBreak, limit: states.limit || 100, sortBy: states.sortBy || "name_asc" } : filter || {} } });
  }, [app.currentUser?.functions, appContext.state, collection, getData, limit, pagination, relations, search, sort]);
  return (
    <>
      {(loading || paginateLoading) ? (
        <Loading />
      ) : error ? (
        <div className="m-2 text-red-700">
          データ取得エラー
          <br />
          {error}
        </div>
      ) : (
        <SortableListFrame
          collection={collection}
          location={location}
          heads={heads}
          filter={filter}
          download={download}
          noAdd={Boolean(noAdd)}
          pagination={Boolean(pagination)}
          paginatedData={paginatedData}
          setPaginatedData={setPaginatedData}
          count={count}
          Data={pagination ? paginatedData : Data}
          Row={row}
          Dialog={dialog}
        />
      )}
    </>
  );
};

export default DataList;
