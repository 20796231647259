import React, { ReactNode } from "react";
import * as Realm from "realm-web";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail as updateEmailFirebase,
  EmailAuthProvider,
  reauthenticateWithCredential,
  Auth,
//  deleteUser as deleteUserFirebase,
} from "firebase/auth";

import SignIn from "Components/SignIn";
import { handleException } from "contexts/errors";
import RequireVerify from "Components/RequireVerify";

const firebaseConfig = {
  apiKey: "AIzaSyBBBYjp12wVKZKrUsDkqxBzkzPSjUZHZY4",
  authDomain: "kusayakyu-app.firebaseapp.com",
  projectId: "kusayakyu-app",
  storageBucket: "kusayakyu-app.appspot.com",
  messagingSenderId: "737055185299",
  appId: "1:737055185299:web:802280cdcadf109fc97066",
  measurementId: "G-9ZG6HCPL8Q",
};
initializeApp(firebaseConfig);
const auth = getAuth();

interface RealmAppContextInterface {
  app: Realm.App;
  auth: Auth;
  currentUser: Realm.User | null;
  logIn: (credentials: Realm.Credentials) => Promise<void>;
  logOut: () => Promise<void>;
}

const RealmAppContext = React.createContext<RealmAppContextInterface>(
  {} as RealmAppContextInterface
);

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return appContext;
};

export const sendVerification = async () => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    await sendEmailVerification(auth.currentUser);
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const update = async (currentPassword:string, password: string) => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    const credential = EmailAuthProvider.credential(auth.currentUser.email || "", currentPassword)
    await reauthenticateWithCredential(auth.currentUser, credential)
    await updatePassword(auth.currentUser, password);
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const updateEmail = async (currentPassword:string, email: string) => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    const credential = EmailAuthProvider.credential(auth.currentUser.email || "", currentPassword)
    const userCredential = await reauthenticateWithCredential(auth.currentUser, credential)
    await updateEmailFirebase(auth.currentUser, email);
    if (userCredential?.user) await sendEmailVerification(userCredential.user)
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const reset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return null;
  } catch (e:any) {
    return handleException(e);
  }
};
export const deleteUser = async (app:RealmAppContextInterface) => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    if (!window.confirm("チームのアカウントを削除します。この操作は取り消せません。よろしいですか？")) return null;
//    await deleteUserFirebase(auth.currentUser);
//    await app.currentUser?.functions.deleteAppUser(app.currentUser?.id)
//    await app.logOut();
    return null;
  } catch (e) {
    return handleException(e);
  }
};

export const RequireLoggedInUser = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const realmAppContext = useRealmApp();
  const signin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const token = (await auth.currentUser?.getIdToken()) || "";
      await realmAppContext.logIn(Realm.Credentials.jwt(token));
    } catch (e) {
      return handleException(e);
    }
  };
  const refresh = async () => {
    try {
      const token = (await auth.currentUser?.getIdToken()) || "";
      await realmAppContext.currentUser?.logOut();
      await realmAppContext.logIn(Realm.Credentials.jwt(token));
    } catch (e) {
      return handleException(e);
    }
  };
  const register = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (userCredential?.user) await sendEmailVerification(userCredential.user)
      return null;
    } catch (e) {
      return handleException(e);
    }
  };

  return realmAppContext.currentUser ? (
    realmAppContext.currentUser?.profile?.emailVerified ? (
      children
    ) : (
      <RequireVerify send={sendVerification} refresh={refresh} logout={realmAppContext.logOut} />
    )
  ) : (
    <SignIn signin={signin} register={register} reset={reset} />
  );
};

export const RealmAppProvider = ({
  appId,
  children,
}: {
  appId: string;
  children: ReactNode;
}) => {
  const firstRun = React.useRef<boolean>(true);
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));
  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  async function logIn(credentials: Realm.Credentials) {
    await app.logIn(credentials);
    setCurrentUser(app.currentUser);
  }
  async function logOut() {
    await app.currentUser?.logOut();
    await auth.signOut();
    setCurrentUser(app.currentUser);
  }

  const realmAppContext = { app, auth, currentUser, logIn, logOut };

  return (
    <RealmAppContext.Provider value={realmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
