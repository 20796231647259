import React, { ReactElement, ChangeEvent, FocusEvent, MouseEventHandler, KeyboardEventHandler, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { FieldValidator, Form, Formik, FormikProps } from 'formik';
import { PlusCircleIcon, CheckCircleIcon, EyeIcon, XCircleIcon, ChevronUpIcon } from '@heroicons/react/solid'

import { useReplace, useAdd, useDatum } from "graphql/useCollection";
import { FieldPlain, FieldNumber, FieldArea, FieldCheck, FieldWithMark, FieldSelect } from './uiparts'
import DatePicker from './datepicker'
import Loading from "./Loading";
import options from "contexts/options.json"
import { setAddress, isKeyValueObject, ButtonClass } from "utils" 
import { AnyObject, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { useRealmApp } from 'RealmApp';
import { JSTDateTime } from 'contexts/dateUtils';


export const getError = (formik:FormikProps<any> | undefined, name:string) => {
  if (!formik) return false
  return formik.touched[name] && formik.errors[name]
}

const FormHeader = ({ title, create = false }:{title:string, create?: boolean}) => (<div className="md:col-span-1">
    <div className="p-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-600">
            {title}を{create ? "新規作成" : "編集"}します
            </p>
    </div>
</div>)

export const FormButton = ({ completed = null }:{completed:(() => void) | null}) => (<>
    <button
        type="submit"
        className={ButtonClass}
    >
        <CheckCircleIcon className="w-5 h-5"/>
        確定
    </button>
    <button
        className={ButtonClass}
        onClick={e => { e.preventDefault(); completed && completed(); }}
    >
        <XCircleIcon className="w-5 h-5" />
        戻る
    </button>
</>)

const handleZipChange = async (formik: FormikProps<any> | undefined, event:ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  let value = event.target.value || "";
  setAddress(value, name.replace(".zip", ""), formik);
  formik?.handleChange(event);
};

// put item if customer or user, else set value
export const RelationField = ({ label, onClick, value, item, withButton = false, span = 3 }:{ label:string, onClick:MouseEventHandler<HTMLDivElement | SVGSVGElement>, value?:string | undefined, item?:KV, withButton?:boolean, span?:number }) => (<div className={`relative col-span-6 sm:col-span-${span}`}>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div
        className="mt-1 py-2 px-3 text-sm h-9 border border-gray-300 rounded cursor-pointer"
        onClick={onClick}
    >
        {item && `${(item.name ? item.name : "")}${(item.surname || item.name?.surname || "")}　${(item.givenName || item.name?.givenName || "")}`}
        { value && value }
    </div>
    {withButton && <EyeIcon name="openView" className="absolute right-9 bottom-1 w-8 h-8 cursor-pointer" onClick={onClick} />}
    {withButton && <PlusCircleIcon name="openCreate" className="absolute right-1 bottom-1 w-8 h-8 cursor-pointer" onClick={onClick} /> }
</div>)

// put item if customer or user, else set value
export const CustomRelationField = ({ label, onClick, value, item, withButton = false, span = 3 }:{ label:string, onClick:MouseEventHandler<HTMLDivElement | SVGSVGElement>, value:string, item?:KV, withButton?:boolean, span:number }) => (<div className={`relative col-span-6 sm:col-span-${span}`}>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div
        className="mt-1 py-2 px-3 text-sm h-9 border border-gray-300 rounded cursor-pointer"
        onClick={onClick}
    >
        {item && `${item.name} ${(item.surname || item.name?.surname || "")}　${(item.givenName || item.name?.givenName || "")}`}
        { value && value }
    </div>
    {withButton && <EyeIcon name="openView" className="absolute right-9 bottom-1 w-8 h-8 cursor-pointer" onClick={onClick} />}
</div>)

export const DisabledField = ({ label, value, span = 3 }: { label: string, value:string, span:number }) => (
  <div className={`relative col-span-6 sm:col-span-${span}`}>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div className="mt-1 py-2 px-3 text-sm h-9 border border-gray-300 rounded">
      {value && value}
    </div>
  </div>
);


// Get initial default values from schema
export const getSchemaValues = (schema: any): KV => {
  return Object.keys(schema).reduce((a, r) => {
    if (schema[r].bsonType === "object")
      return {
        ...a,
        [r]: getSchemaValues(schema[r].properties),
      };
    if (schema[r].bsonType === "array") return { ...a, [r]: [] };
    let value = schema[r].description?.match(/[^{}]*(?=\})/g)?.[0];
    if (value === "TODAY") {
      let date = new Date();
      date.setHours(12, 0, 0, 0);
      value = date;
    }
    return { ...a, [r]: value || "" };
  }, {});
};

/**
 * Add schema and formik to children.  Return null if show is false.
 * @param  param0 {show, children, schema, formik}
 * @returns 
 */
export const FormFrame = ({ show = true, children, schema, formik }:{ show?:boolean; children:JSX.Element | JSX.Element[], schema:KV, formik:FormikProps<any> }) => {
    if (!show) return null
    return (<>
        {React.Children.toArray(children).map(child => React.cloneElement(child as ReactElement<any>, { schema: schema, formik: formik }))}
    </>)
}

export const FieldBase = ({
  formik,
  schema,
  parent,
  name,
  mark,
  span,
  inputType,
  option,
  onChange,
  onBlur,
  validate,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  mark?: string;
  span?: number;
  inputType?: string;
  option?:{[key:string]:string|number};
  onChange?: (e: ChangeEvent<HTMLFormElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  validate?: FieldValidator | undefined
}) => {
  const fullName = (parent ? parent + "." : "") + name;
  //    const obj = fullName.split(".").reduce((a, r) => a[r] || a.properties[r], schema)
  const obj = schema && schema[name.replace(/\[[\s\S]*?\]/g, "")];
  const type = (inputType && [inputType]) || obj.description
    ?.replace(/\([\s\S]*?\)/g, "")
    .replace(/\{[\s\S]*?\}/g, "")
    .replace(/<[\s\S]*?>/g, "")
    .split(":") || ["text"];
  //    const title = obj.title || obj.description?.match(/(?<=<)[^\][]*(?=>)/g)?.[0]
  const title = obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan =
    span || obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";
  let props:KV = { ...(formik?.getFieldProps(fullName) || {}) }
  if (onChange) props.onChange = onChange
  if (onBlur) props.onBlur = onBlur

  const { registerField, unregisterField } = formik || { registerField: undefined, unregisterField: undefined };
  useEffect(() => {
    validate && registerField && registerField(name, {
      validate: validate,
    });
    return () => {
      validate && unregisterField && unregisterField(name);
    };
  }, [registerField, unregisterField, name, validate]);

  switch (type[0]) {
    case "select":
      return (
        <FieldSelect
          label={title}
          name={fullName}
          span={actualSpan}
          options={option || options[type[1]]}
          props={props}
        />
      );
    case "date":
      return (
        <DatePicker
          label={title}
          name={fullName}
          span={actualSpan}
          formik={formik}
          enableHistory
        />
      );
    case "check":
      return (
        <FieldCheck
          label={title}
          name={fullName}
          span={actualSpan}
          props={props}
          setFieldValue={formik?.setFieldValue}
        />
      );
    case "zip":
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleZipChange(formik, e),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "disabled":
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{ ...(formik?.getFieldProps(fullName) || {}), disabled: true }}
        />
      );
      case "disabledDate":
        return (
          <FieldPlain
            label={title}
            name={fullName}
            span={actualSpan}
            props={{ ...(formik?.getFieldProps(fullName) || {}), value:(formik?.values[fullName] ? JSTDateTime(new Date(formik?.values[fullName])) : undefined), disabled: true }}
          />
        );
      case "decimal":
    case "int":
      return type[1] ? (
        <FieldWithMark
          label={title}
          mark={type[1]}
          name={fullName}
          span={actualSpan}
          props={props}
          error={String(getError(formik, fullName) || "")}
        />
      ) : (
        <FieldNumber
          label={title}
          name={fullName}
          span={actualSpan}
          props={props}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "mark":
      return (
        <FieldWithMark
          label={title}
          name={fullName}
          mark={mark || ""}
          span={actualSpan}
          props={props}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "area":
      return (
        <FieldArea
          label={title}
          name={fullName}
          span={actualSpan}
          props={props}
          error={String(getError(formik, fullName) || "")}
        />
      );
    default:
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={props}
          error={String(getError(formik, fullName) || "")}
        />
      );
  }
};

// Pass formik, schema and parent name to child(parent is object)
export const FieldGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  title:string;
  children: JSX.Element[];
}) => {
  const obj = schema?.[name.replace(/\[[\s\S]*?\]/g, "")] || schema;
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{actualTitle}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name,
          schema: obj.properties,
          formik: formik,
        })
      )}
    </div>
  );
};

// Pass formik, schema and parent name to child(parent is array)
export const FieldArrayGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik: FormikProps<any>;
  schema: KV;
  parent: string;
  name: string;
  title: string;
  children: JSX.Element;
}) => {
  const obj = schema[name.split(".")[0]] || schema;
  //    const obj = schema[name.replace(/\[[\s\S]*?\]/g, '')] || schema
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{actualTitle}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name,
          schema: obj.items.properties,
          formik: formik,
        })
      )}
    </div>
  );
};

// Pass formik, schema and parent name to child
export const FoldableFieldGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik: FormikProps<any>;
  schema: KV;
  parent: string;
  name: string;
  title: string;
  children: JSX.Element;
}) => {
  const [open, setOpen] = useState(false);
  const obj = schema[name.replace(/\[[\s\S]*?\]/g, "")] || schema;
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";
  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} bg-gray-100`}
    >
      <button
        className="flex justify-between px-4 py-2 w-full text-theme-800 bg-gray-200 rounded-lg"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <span>{actualTitle}</span>
        <ChevronUpIcon
          className={`${
            open ? "transform rotate-180" : ""
          } w-5 h-5 text-theme-800`}
        />
      </button>
      <div className={`grid grid-cols-6 gap-4 ${open ? "" : "hidden"}`}>
        {React.Children.toArray(children).map((child) =>
          React.cloneElement(child as ReactElement<any>, {
            parent: (parent ? parent + "." : "") + name,
            schema: obj.properties,
            formik: formik,
          })
        )}
      </div>
    </div>
  );
};


/**
 * Pass formik, schema and parent name to child
 * @param {*} param0 
 * @returns React.component
 */
export const FieldArray = ({
  formik,
  schema,
  parent,
  name,
  children,
}: {
  formik: FormikProps<any>;
  schema: KV;
  parent: string;
  name: string;
  children: JSX.Element;
}) => {
  const obj = schema[name];
  const title = obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{title}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name + "[*]",
          schema: obj.items,
          formik: formik,
        })
      )}
    </div>
  );
};

/**
 * 
 * @param {*} Data 
 * @returns 
 */
export const getObjectData = (Data:KV):KV => (
    Object.keys(Data).reduce((a, key) => {
        if (key === "__typename") return a;
        if (!Data[key]) return ({...a, [key]: ""})
        return ({
            ...a, [key]: (isKeyValueObject(Data[key]) ? getObjectData(Data[key]) : (Array.isArray(Data[key]) ? Data[key].map((datum:any) => isKeyValueObject(datum) ? getObjectData(datum) : datum) : Data[key]))
    })}, {})
)

/**
 * Clean database response data removing null item and __typename. Add data from initial value if not exist in Data.
 * if Data[key] don't exist, use values[key] if exist.
 * @param {*} values Default value object. This value is used if value is not provided by Data
 * @param {*} Data Value object returned from GraphQL query.  Contains __typename field for object.
 * @returns Cleaned value objects
 */
export const getData = (values:KV, Data:KV):KV => {
    return (
    Object.keys(values || Data).reduce((a, key) => {
        if (key === "__typename") return a;
        return ({ ...a, [key]: (
            !Data[key] ? 
            (values ? values[key] : "") : // All type including object and array
                (isKeyValueObject(Data[key]) ? getData(values[key], Data[key]) : (Array.isArray(Data[key]) ? Data[key].map((datum:any) => isKeyValueObject(datum) ? getObjectData(datum) : datum) : Data[key]))
        )})
    }, {})
)}

// remove no value item(incl. empty array or object) and set relation link.  Number to string.  Get foreign_key value from spreaded relation values and set link value.  Deep copy.
export const getUpdatingData = (Data:KV, relations:KV={}):KV => {
    const relationKeys = Object.keys(relations)
    return Object.keys(Data).reduce((a, key) => {
        const value = Data[key]
        if (!value || value === "0") return a
        if (key === "__typename") return a;
        if (relationKeys.includes(key)) {
          if (Array.isArray(value)) return value.length ? { ...a, [key]: { link:value.map(v => v[relations[key].foreign_key]) }} : a
          return { ...a, [key]: { link: value[relations[key].foreign_key] }}
        }
        if (Array.isArray(value)) {
            if (value.length) return { ...a, [key]: value.map(v => 
                isKeyValueObject(v) ? getUpdatingData(v) : (typeof v === "number" ? v.toString() : v)
            )}
            return a
        }
        if (isKeyValueObject(value)) {
            const objectValue = getUpdatingData(value)
            if (Object.keys(objectValue).length) return { ...a, [key]: objectValue }
            return a
        }
        return { ...a, [key]: typeof value === "number" ? value.toString() : value}
    }, {})
}

const onKeyDown:KeyboardEventHandler<HTMLFormElement> = (keyEvent) => {
    if ((keyEvent.target as HTMLElement).localName !== "textarea" && keyEvent.key === "Enter") keyEvent.preventDefault();
}

/**
 * 
 * @param obj.Fields Function to render form fields.  Create prop is undefined 
 * @returns 
 */
export const EditBase = ({
  title,
  collection,
  values,
  location,
  relations = {},
  Fields,
  validation,
  readPrepare,
  writePrepare,
  afterEdit,
  userUpdate = false
}: {
  title: string;
  collection: string;
  values: KV;
  location?: string;
  relations?: KV;
  Fields: ({ formik, create }: { formik: FormikProps<any>, create?:boolean }) => JSX.Element;
  validation: OptionalObjectSchema<KV, AnyObject, TypeOfShape<any>>;
  readPrepare?: (values: KV) => void;
  writePrepare?: (
    updates: KV,
    values: KV
  ) => void;
  afterEdit?: (
    updates: KV,
    mongo?: Realm.Services.MongoDB
  ) => Promise<void>;
  userUpdate?:boolean;
}) => {
  const realmApp = useRealmApp()
  let { id } = useParams();
  const navigate = useNavigate();
  const [writing, setWriting] = useState(false)
  const completed = () => {
    if (userUpdate) {
      realmApp.currentUser?.refreshCustomData()
    }
    navigate(location || `/${collection.toLowerCase()}`);
  };
  const { loading, Data } = useDatum(collection, { _id: id });
  const updateData = useReplace(collection);
  let modifiedData = loading ? {} : getData(values, Data);
  if (!loading && readPrepare) readPrepare(modifiedData);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="md:grid md:grid-cols-4 md:gap-6">
          {writing && <Loading modal />}
          <FormHeader title={title} />
          <div className="mt-5 md:mt-0 md:col-span-3">
            <Formik
              initialValues={modifiedData}
              validationSchema={validation}
              onSubmit={async (values, { setSubmitting }) => {
                let updates = getUpdatingData(values, relations);
                setWriting(true)
                if (writePrepare) writePrepare(updates, modifiedData);
                const { replacedData, error } = await updateData(
                  modifiedData._id,
                  updates
                );
                if (error) alert("データ編集エラー：" + error.message)
                else if (afterEdit) await afterEdit(updates, realmApp.currentUser?.mongoClient("mongodb-atlas"));
                setWriting(false)
                setSubmitting(false);
                if (replacedData) completed();
              }}
            >
              {(formik) => (
                <Form onKeyDown={onKeyDown}>
                  <div className="shadow sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <Fields formik={formik} />
                    </div>
                    <div className="px-4 bg-gray-50 text-right sm:px-6">
                      <FormButton completed={completed} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export const CreateBase = ({
  title,
  collection,
  values,
  location,
  relations = {},
  Fields,
  completed,
  validation,
  writePrepare,
  afterEdit,
  unique
}: {
  title: string;
  collection: string;
  values: KV;
  location?: string;
  relations?: KV;
  Fields: ({ formik, create }: { formik: FormikProps<any>, create?:boolean }) => JSX.Element;
  completed?: (id?: string, values?: KV) => void;
  validation: OptionalObjectSchema<KV, AnyObject, TypeOfShape<any>>;
  writePrepare?: (
    updates: KV,
    values: KV
  ) => void;
  afterEdit?: (
    updates: KV,
    mongo?:Realm.Services.MongoDB
  ) => Promise<void>;
  unique?: string;
}) => {
  const realmApp = useRealmApp()
  const navigate = useNavigate();
  const [writing, setWriting] = useState(false)
  const actualCompleted =
    completed ||
    (() => {
      navigate(location || `/${collection.toLowerCase()}`);
    }); //React.useCallback(() => { history.push(location || `/${collection.toLowerCase()}`) }, [history, location, collection])
  const addData = useAdd(collection);

  // Data adding routine which can retry if generated _id already exists
  const addingData = async (
    setSubmitting: (isSubmitting: boolean) => void,
    updates: KV,
    updatingValues: KV
  ) => {
    try {
      console.log(updates)
      setWriting(true)
      const { addedData } = await addData(updates);
      if (afterEdit) await afterEdit(updates, realmApp.currentUser?.mongoClient("mongodb-atlas"))
      setWriting(false)
      setSubmitting(false);
      if (addedData) actualCompleted(addedData._id, updatingValues);
    } catch (e: any) {
      if (e.message.indexOf("Duplicate key error") > -1) {// Be careful when customizing

        const key = e.message.match(/(?:dup key: { )\S*(?=:)/g)[0].replace("dup key: { ", "")
        if (updates[key]) { // When set id by user
          alert(
            "データ追加エラー：\n指定したIDはすでに存在します。別のIDを指定してください。"
          );
          setWriting(false)
          setSubmitting(false);
        } else {
          await addingData(setSubmitting, updates, updatingValues);
        }
      } else {
        alert("データ追加エラー：\n" + e.message);
        setWriting(false)
        setSubmitting(false);
      }
    }
  };
  return (
    <div className="md:grid md:grid-cols-4 md:gap-6">
      {writing && <Loading modal />}
      <FormHeader title={title} create />
      <div className="mt-5 md:mt-0 md:col-span-3">
        <Formik
          initialValues={values}
          validationSchema={validation}
          onSubmit={async (updatingValues, { setSubmitting }) => {
            let updates = getUpdatingData(updatingValues, relations);
            if (writePrepare) writePrepare(updates, values);
            addingData(setSubmitting, updates, updatingValues)
          }}
        >
          {(formik) => (
            <Form onKeyDown={onKeyDown}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <Fields formik={formik} create/>
                </div>
                <div className="px-4 bg-gray-50 text-right sm:px-6">
                  <FormButton completed={actualCompleted} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
