import React from "react"
import { Link } from "react-router-dom"
import { ButtonClass } from "utils"

const Page = () => {
    return (<div className="w-full max-w-screen-sm mx-auto h-full flex flex-col items-center justify-center">
        <div className="text-lg text-theme-800 font-medium leading-6">試合申し込みを受け付けました</div>
        <Link to='/team'><button name="top" className={ButtonClass}>ホーム</button></Link>
    </div>)

}

export default Page