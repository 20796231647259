import { excludes, excludesSecondLast, isSecondLast, requestingTime, resultingTime } from "contexts/AppContext"
import Loading from "Components/Loading"
import { JSTMonthDateString, JSTMonthDateStringWithWeekday, JSTNextSunday, JSTToday, setJSTHour } from "contexts/dateUtils"
import { useData } from "graphql/useCollection"
import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useRealmApp } from "RealmApp"
import { ButtonClass } from "utils"

const Page = () => {
    const app = useRealmApp()
    const navigate = useNavigate()
    const customData = app.currentUser?.customData
    const interval = useRef<number>()
    const [now, setNow] = useState(new Date())

    const nextDate = JSTNextSunday(JSTToday(), String(customData?.league || "").includes("土") ? -24 : 0);

    const { loading, Data } = useData("request", { "date_gte":setJSTHour(now, 0), "team": { "_id": app.currentUser?.id || "" }}, "DATE_ASC")
    if (!customData || !customData.teamId) navigate("/team/merge")
    useEffect(() => {
        interval.current = window.setInterval(() => setNow(new Date()), 3600000)
        return (() => clearInterval(interval.current))
    })
    /*if (!customData || !customData.name || !customData.teamId) return (<div className="max-w-sm mx-auto h-full flex flex-col items-center justify-center">
        <span>チーム情報を設定してください</span>
        <Link to={`/team/edit/${app.currentUser?.id}`}><button name="edit" className={ButtonClass}>設定画面</button></Link>
    </div>)*/
    let dateoff = [...excludes, ...excludesSecondLast].find(exclude => exclude > now.getTime() && exclude - now.getTime() < 2592000000) || ((now.getTime() >= excludesSecondLast[0]) ? excludesSecondLast[0] : undefined)
    let Message = () => <></>
    if (dateoff) {
        const dateSat = new Date(dateoff - 86400000) // last day
        const dateSun = new Date(dateoff)
        const dateNextSun = new Date(dateoff + 604800000) // next week
        if (dateoff < excludesSecondLast[0]) Message = () => <li><span className="underline font-medium">{JSTMonthDateStringWithWeekday(dateSat)}・{JSTMonthDateStringWithWeekday(dateSun)}はお休み</span>となります。(試合開催はありません)</li>
        else 
        Message = () => <li>{isSecondLast(String(app.currentUser?.customData?.league || " ")) 
        ? <>日曜大会(5部・初級部)は、開幕時に配布いたしました大会パンフレット記載の通り、<span className="underline font-medium">{JSTMonthDateStringWithWeekday(dateNextSun)}がレギュラーシーズン最終日</span>です。ただし、日曜大会(4部・5部・初級部)は、<span className="underline font-medium">{JSTMonthDateStringWithWeekday(dateSun)}はお休み</span>となります。(試合開催はありません)</>
         : <>土曜大会および日曜大会(1部・2部・3部・4部)は、開幕時に配布いたしました大会パンフレット記載の通り、<span className="underline font-medium">{JSTMonthDateStringWithWeekday(dateSat)}・{JSTMonthDateStringWithWeekday(dateSun)}がレギュラーシーズン最終日</span>です。</>}</li>
    }
    return (loading ? <Loading modal/> : <div className="w-full max-w-screen-sm mx-auto h-full flex flex-col items-center justify-center">
        <div className="rounded-lg shadow-xl w-80">
            <div className="p-2 text-white bg-theme-800 font-medium leading-6 rounded-t-lg">{`${app.currentUser?.customData?.name || " "} （${app.currentUser?.customData?.place || ""}）`}</div>
            <div className="flex justify-center bg-theme-50">
            {requestingTime(String(customData?.league || ""), now) && <Link to={`/team/request${(Data && Data.length && new Date(Data[Data.length -1].date).getTime() === nextDate.getTime()) ? ("/"+Data[Data.length - 1]?._id || "") : ""}`}><button name="request" className={ButtonClass}>試合申込</button></Link>}
            {resultingTime(String(customData?.league || ""), now) && <Link to='/team/result'><button name="result" className={ButtonClass}>結果報告</button></Link>}
            </div>
            {Data && Data.map(request => <div className="px-2 py-4 space-y-2" key={request._id}>
                <div className="border border-gray-400">
                    <div className="px-2 py-1 font-bold">{`${JSTMonthDateString(new Date(request.date))}の試合について`}</div>
                    <div className="px-2 py-1">{request.status === "cancel" ? "今週の試合は中止となりました" : request.status === "confirmed" ? "試合が組まれましたので、テレホンサービスにて詳細をご確認ください" : request.status === "no" ? "残念ながら試合は組まれませんでした" : request.double ? "試合申し込みをダブルヘッダー希望で受け付けました。\r\n(なお、ダブルヘッダーを希望されても、相手チームの都合等の関係で、希望通り組めない場合がありますので、予めご了承ください)" : "試合申し込みを受け付けました"}</div>
                </div>
            </div>)}
            <ul className="px-2 py-2 text-sm bg-gray-50 asterisk">
            <Message />
            <li>試合の申込の受付期間は、前週の土曜日の0時から当該週の火曜日の13時までです(厳守)</li>
            <li>試合結果の報告の〆切は、試合当日の24時までです(厳守)</li>            
            </ul>
        </div>
    </div>)

}

export default Page