import { MouseEvent } from "react";

import { properties as league } from "LeagueApp/data_sources/mongodb-atlas/League/league/schema.json";
import {
  DataList,
  ListItem,
  EditLinkTd,
} from "Components/List";
import options from "contexts/options.json";
const location = "/admin/league";

const getTitle = (obj: {
  bsonType?: string;
  title?: string;
  description?: string;
}) => obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0] || "";
const heads: { [key: string]: string }[] = [
  { text: getTitle(league._id), field: "_id" },
  { text: getTitle(league.league), field: "league" },
  { text: getTitle(league.group), field: "group" },
  { text: getTitle(league.weekday), field: "weekday" },
  { text: getTitle(league.teams), field: "teams" },
  { text: "", field: "" },
];

const row = ({
  datum,
  clickDelete,
}: {
  datum: KV;
  clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <>
    <ListItem data={[datum._id]} />
    <ListItem data={[options.league[datum.league]]} />
    <ListItem data={[options.group[datum.group]]} />
    <ListItem data={[options.weekday[datum.weekday]]} />
    <ListItem data={[datum.teams?.length || 0]} />
    <EditLinkTd
      location={location}
      id={datum._id}
      onClickDelete={clickDelete}
    />
  </>
);

const List = () => (
  <DataList
    collection="league"
    location={location}
    heads={heads}
    row={row}
  />
);

export default List;
