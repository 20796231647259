import React from "react";
import { RealmAppProvider } from "RealmApp";

import Router from "Components/Router";
import { BrowserRouter } from "react-router-dom";

export const APP_ID = "leagueapp-ofzll";


const App = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center sm:bg-diamond bg-center bg-auto 2xl:bg-cover">
        <RealmAppProvider appId={APP_ID}>
          <BrowserRouter basename="app">
            <Router />
          </BrowserRouter>
        </RealmAppProvider>
      </div>
  );
};

export default App;
