import * as Yup from 'yup';

import { properties as schema } from "LeagueApp/data_sources/mongodb-atlas/League/request/schema.json"
import relations from "LeagueApp/data_sources/mongodb-atlas/League/request/relationships.json"
import { getSchemaValues, EditBase, FormFrame, FieldBase, CreateBase, RelationField } from 'Components/edit'
import { FormikProps } from 'formik';
import { useState } from 'react';
import TeamSearch from '../team/TeamSearch';

const FormFields = ({ formik }: { formik: FormikProps<any> }) => {
    const [showTeam, setShowTeam] = useState(false)

    return <FormFrame formik={formik} schema={schema}>
        <FieldBase name="date" />
        <RelationField label="チーム" onClick={e => setShowTeam(true)} item={formik.values.team} />
        <TeamSearch field="team" show={showTeam} setShow={setShowTeam} formik={formik} />
        <FieldBase name="double" />
        <FieldBase name="createdAt" />
        <FieldBase name="modifiedAt" />
        <FieldBase name="status" />
        <FieldBase name="remarks" />
    </FormFrame>
}

const validationSchema = Yup.object({
    repName: Yup.object({
        surname: Yup.string()
            .max(15, 'Must be 15 characters or less'),
        surnameKana: Yup.string()
            .max(15, 'Must be 15 characters or less'),
        givenName: Yup.string()
            .max(20, 'Must be 20 characters or less'),
    }),
    email: Yup.string().email('無効なメールアドレスです'),
})


const createPrepare = (updates: KV) => {
    updates.createdAt = new Date()
}

const writePrepare = (updates: KV) => {
    updates.modifiedAt = new Date()
}

export const Create = () => {
    return (
        <CreateBase
            title="チーム出欠情報"
            values={getSchemaValues(schema)}
            location="/admin/request"
            collection="request"
            relations={relations}
            Fields={FormFields}
            writePrepare={createPrepare}
            validation={validationSchema}
        />);
  };
  

export const Edit = () => {
    return (
        <EditBase
            title="チーム出欠情報"
            values={getSchemaValues(schema)}
            location="/admin/request"
            collection="request"
            relations={relations}
            Fields={FormFields}
            writePrepare={writePrepare}
            validation={validationSchema}
        />
    );
};
export default Edit;
