import Decimal from 'decimal.js';



export const formatNumber = (num) => num ? String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ""
export const formatCurrency = (num) => String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円'
export const isObject = (value) => (value && typeof value === 'object' && value.constructor === Object)
export const isKeyValueObject = (value) => (value && typeof value === 'object' && value.constructor === Object && !(value instanceof Date))


export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ""

// no use
export const times = (a, b) => {
    const x = new Decimal(a || 0)
    const y = new Decimal(b || 0)
    return x.times(y).floor()
}
// no use
export const getParams = (schema, prefix = "") => {
    return Object.keys(schema).map(key => schema[key].bsonType === "object" ? getParams(schema[key].properties, key) : (prefix + schema[key].title)).flat(Infinity)
}

// nested json type object by dotted string key
export const getNestData = (data, key) => key.split(".").reduce((a, r) => a ? a[r] : null, data)
export const setNestData = (data, key) => key.split(".").reverse().reduce((a, r) => ({ [r]: a }), data)
export const sortAscend = (field) => (a, b) => {
    let dataa = getNestData(a, field) || "";//null is object, so make default value "" to change to string. cannnot parse to int or float, but considered as 0 when compare with number
    let datab = getNestData(b, field) || "";
    if (typeof dataa === "string" && typeof datab === "string") {// Js process if both is string, compare as string "-3" > "-2", if either is number, compare as number -3 < "-2"
        return +(dataa > datab) || -(dataa < datab)
    }
    dataa = parseFloat(dataa) || dataa;
    datab = parseFloat(datab) || datab;
    return +(dataa > datab) || -(dataa < datab)
}
export const sortDescend = (field) => (a, b) => {
    let dataa = getNestData(a, field) || "";
    let datab = getNestData(b, field) || "";
    if (typeof dataa === "string" && typeof datab === "string") return -(dataa > datab) || +(dataa < datab)
    dataa = parseFloat(dataa) || dataa;
    datab = parseFloat(datab) || datab;
    return -(dataa > datab) || +(dataa < datab)
}

export const setAddress = async (zipcode, parent, formik) => {
    if (!(/^[0-9]{3}[-]?[0-9]{4}$/.test(zipcode))) return
    try {
        const res = await fetch(`https://kmdsbng.github.io/zipcode_jp/zip_code/${zipcode.substr(0, 3)}/${zipcode.replace('-', '')}.json`)
        const json = await res.json()
        formik.setFieldValue(`${parent}.state`, json[0].prefecture_jis_code)
        formik.setFieldValue(`${parent}.citystr`, json[0].city_name + json[0].town_name)
    } catch { }
}

export const ButtonClass = "inline-flex justify-center items-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500 disabled:bg-theme-300"

