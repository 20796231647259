import { ReadableWebToNodeStream } from "readable-web-to-node-stream";
import ExcelJS from 'exceljs'
import { Stream } from "stream";
import { getCellByName } from "./main";

/**
 * Write results to Excel file.
 * Read from database using Realm find.  Write to database by using Realm updateMany,  Mongo bulkWrite(for many updateOne)
 * Set cell name "score*" somewhere on a column(<20) including score.
 * Set cell name "teamid1*", "teamid2" somewhere on a column(<20) including teamid.  Other values should not exist on these columns
 * @param {Date} date Date to process(Sunday)
 * @param {File} file Excel file to write result data. 
 * @param {(Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory)} functions Realm functions. 
 * @param {Realm.Services.MongoDB} mongo MongoDB client. 
 * @param {function(string, boolean?, boolean?):void} setMessages Message dispatcher. 
 * @returns {void}
 */
 export const writeResultFile = async (date: Date, file: File, functions: (Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory), mongo: Realm.Services.MongoDB, setMessages: (message: string, error?: boolean, add?: boolean) => void) => {
    const dates = [date, new Date(date.getTime() - 86400000)]
    // Read Excel file
    const stream = new ReadableWebToNodeStream(file.stream());
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(stream as unknown as Stream)


    // Find score and teamid cell for each sheet
    let scorecols: { [key: number]: number } = {}
    let noCellname = false
    /** @type {{[key:number]:{[key:string]:number}}} Match Card objects read from sheet for each team.  Key is teamid */
    let cards: Card[] = []
    workbook.eachSheet((worksheet, sheetId) => {
        const [team1cell, team2cell, scorecell, groupCell] = [getCellByName(worksheet, 'teamid1'), getCellByName(worksheet, 'teamid2'), getCellByName(worksheet, 'score'), getCellByName(worksheet, 'group')]
        if (!team1cell || !team2cell || !scorecell || !groupCell) {
            setMessages(`${worksheet.name}でチームID、スコアまたは階級のセルに名前がありません`, true)
            noCellname = true
            return null
        }
        scorecols[sheetId] = scorecell.fullAddress.col
        const col1 = team1cell.fullAddress.col
        const col2 = team2cell.fullAddress.col
        const colgroup = groupCell.fullAddress.col
        worksheet.eachRow(row => {
            const id1 = Number(row.getCell(col1).value)//nan is falsey
            const name1 = `${row.getCell(col1+1).value}(${row.getCell(col1+2).value})`
            const id2 = Number(row.getCell(col2).value)
            const name2 = `${row.getCell(col2+1).value}(${row.getCell(col2+2).value})`
            const group = (worksheet.name.includes("土曜") ? "土" : "") + String(row.getCell(colgroup).value)
            if (id1 && id2) {
                cards.push({ id: "", league: group, team: id1, name:name1, opponentName:name2, sheet: sheetId, row: row.number, opponent: id2, position: 0, score: -1, opponentScore: -1, status: "unset" })
                cards.push({ id: "", league: group, team: id2, name:name2, opponentName:name1, sheet: sheetId, row: row.number, opponent: id1, position: 1, score: -1, opponentScore: -1, status: "unset" })
            }
        })
    })
    if (noCellname) return null

    let sheetError = false
    let lostList: Set<string> = new Set()

    let results: KV[] = []
    try {
        results = await mongo.db("League").collection("result").find({ date: { $in: dates } })
    } catch (e) {
        setMessages("データベースからデータの読込中にエラーが発生しました", true)
        return null
    }


    for (const result of results) {
        console.log(result)
        //        if (["cancel"].includes(result.status)) continue;
        const card = cards.find(card => card.team === result.team && card.opponent === result.opponent)
        if (!card) {
            sheetError = true
            setMessages(`シート上に対戦が見つかりません。チーム:${result.team} 対 ${result.opponent}`, true, true)
            lostList.add(result._id)
            continue
        }
        card.id = result._id
        card.score = result.score || 0
        card.opponentScore = result.opponentScore || 0
        const opponentCard = cards.find(opponentCard => card.opponent === opponentCard.team && card.team === opponentCard.opponent) // No notfound case, two card are pushed
        if (!opponentCard) {
            sheetError = true
            setMessages(`シート上に対戦が見つかりません。チーム:${result.team} 対 ${result.opponent}`, true, true)
            lostList.add(result._id)
            continue
        }
        /*"status": {
    "": "未処理"
    "submit": "提出",
    "no": "双方非提出",
    "single": "当方のみ",
    "opponent": "相手のみ",
    "unmatch": "不一致",
    "confirmed": "確定",
    "cancel": "中止",
    "default": "不戦勝/敗",
    "defaultunmatch": "不戦不一致"
  }*/
        const writeSheet = (value: string) => workbook.getWorksheet(card.sheet).getCell(card.row, scorecols[card.sheet]).value = value
        
        // if true, write score from opponent, false write to opponent
        const copyScore = (opponent?: boolean) => {
            if (opponent) {
                card.score = opponentCard.opponentScore;
                card.opponentScore = opponentCard.score;
                card.copied = true
            } else {
                opponentCard.score = card.opponentScore
                opponentCard.opponentScore = card.score
                opponentCard.copied = true
            }
        }
        const setStatus = (status: string, opponentStatus?: string) => {
            card.status = status
            if (opponentStatus) opponentCard.status = opponentStatus
            switch (status) {
                case "cancel": writeSheet("中止"); break;
                case "unmatch": writeSheet("不一致"); break;
                case "defaultunmatch": writeSheet("不戦不一致"); break;
                case "no": writeSheet("双方未提出"); break;
                case "confirmed":
                case "single":
                    writeSheet(card.position ? `${result.opponentScore || "0"} 対 ${result.score || "0"}` : `${result.score || "0"} 対 ${result.opponentScore || "0"}`); break;
                case "default":
                    writeSheet(card.position ? `${result.opponentScore || "0"} 対(不戦) ${result.score || "0"}` : `${result.score || "0"} 対(不戦) ${result.opponentScore || "0"}`); break;
                default:
            }
            if (status === "opponent" && (opponentStatus === "single" || opponentCard.status === "single")) copyScore(true)
            if (opponentStatus === "opponent" || opponentStatus === "default") copyScore()
        }

        if (opponentCard.status === 'cancel' && !['default', 'defaultunmatch'].includes(result.status)) {
            card.status = 'cancel'
        } else if (['default', 'defaultunmatch'].includes(opponentCard.status) && !['default', 'defaultunmatch'].includes(result.status)) {
            card.status = 'default'
            copyScore(true)
        // 相手がキャンセル、不戦勝、不戦勝不一致で、自分が不戦勝、不戦勝不一致でない場合はここまでで処理終了している
        } else if (!result.status || result.status === "no") {
            switch (opponentCard.status) {
                case "unset": setStatus(""); break;
                case "": setStatus("no", "no"); break;
                case "no": setStatus("no"); break;
                case "opponent": setMessages(`当方未提出に対し、相手側が相手のみになっています。チーム:${card.name} 対 ${card.opponentName}`, true, true);break;
                case "single": setStatus("opponent"); break;
                default: setStatus("opponent", "single"); break;
            }
        } else if (['submit', 'single', 'unmatch', 'confirmed'].includes(result.status)) {
            switch (opponentCard.status) {
                case "unset": setStatus("single"); break;
                case "":
                case "no":
                case "opponent": setStatus("single", "opponent"); break;
                default:
                    if (opponentCard.score !== card.opponentScore || opponentCard.opponentScore !== card.score) {
                        setStatus("unmatch", "unmatch")
                        sheetError = true
                        setMessages(`スコアに不整合があります。チーム:${card.name} 対 ${card.opponentName}\r\n${card.score || 0}-${card.opponentScore || 0}　相手提出　${opponentCard.opponentScore || 0}-${opponentCard.score || 0}`, true, true)
                    } else {
                        setStatus("confirmed", "confirmed")
                    }
                    break;
            }
        } else if (result.status === "opponent") {
            switch (opponentCard.status) {
                case "unset":
                case "":
                case "no"://Set score back to use this result
                case "single": setStatus("opponent"); break;//Set score from opponent only opponent is single in setStstus
                case "opponent": setMessages(`当方、相手側共に相手のみになっています。チーム:${card.name} 対 ${card.opponentName}`, true, true);break;
                case "unmatch": setStatus("opponent", "single"); break;
                default:
            }
        } else if (result.status === "cancel") {
            switch (opponentCard.status) {
                case "unset": setStatus("cancel"); break;
                case "":
                case "no":
                case "opponent":
                case "single":
                case "unmatch": setStatus("cancel", "cancel"); break;
                default:
            }
        // ここより上で相手側がキャンセル、不戦勝、不戦勝不一致のケースはない
        } else if (['default', 'defaultunmatch'].includes(result.status)) {
            switch (opponentCard.status) {
                case "unset": setStatus("default"); break;
                case "":
                case "no":
                case "opponent":
                case "single":
                case "unmatch":
                case "cancel": setStatus("default", "default"); break;// write default score to opponent in setStstus
                case "default":
                case "defaultunmatch":
                    if (opponentCard.score !== card.opponentScore || opponentCard.opponentScore !== card.score) {
                        setStatus("defaultunmatch", "defaultunmatch")
                        sheetError = true
                        setMessages(`不戦スコアに不整合があります。チーム:${card.name} 対 ${card.opponentName}\r\n${card.score || 0}-${card.opponentScore || 0}　相手提出　${opponentCard.opponentScore || 0}-${opponentCard.score || 0}`, true, true)
                    } else {
                        setStatus("default", opponentCard.status === "defaultunmatch" ? "default" : undefined)
                    }
                    break;
                default:
            }
        }

    }
    setMessages("スコア集計が完了しました")
    cards.filter(card => card.status === "unset").forEach(card => setMessages(`データベースに対戦がありません　${card.name} - ${card.opponentName}`, true, true))
    cards.filter(card => card.status === "no").forEach(card => setMessages(`双方非提出があります　${card.name} - ${card.opponentName}`, true, true))
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "confirmed").map(v => v.id) } }, { $set: { status: "confirmed" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "unmatch").map(v => v.id) } }, { $set: { status: "unmatch" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "single").map(v => v.id) } }, { $set: { status: "single" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "cancel").map(v => v.id) } }, { $set: { status: "cancel" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "default" && !card.copied).map(v => v.id) } }, { $set: { status: "default" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "defaultunmatch").map(v => v.id) } }, { $set: { status: "defaultunmatch" } })
    await mongo.db("League").collection("result").updateMany({ _id: { $in: cards.filter(card => card.status === "no").map(v => v.id) } }, { $set: { status: "no" } })

    const opponentWrites = cards.filter(card => card.status === "opponent" && card.copied).map(card => ({ updateOne: { filter: { _id: card.id }, update: { $set: { score: card.score, opponentScore: card.opponentScore, status: "opponent", modifiedAt: new Date() } } } }))
    const defaultWrites = cards.filter(card => card.status === "default" && card.copied).map(card => ({ updateOne: { filter: { _id: card.id }, update: { $set: { score: card.score, opponentScore: card.opponentScore, status: "default", modifiedAt: new Date() } } } }))
    if (opponentWrites.length) await functions.bulkWrite(opponentWrites)
    if (defaultWrites.length) await functions.bulkWrite(defaultWrites)
    setMessages("データベースへの書き込みが完了しました")

    const resultCards = cards.filter(card => ["confirmed", "single", "opponent"].includes(card.status) && !card.position).sort((a, b) => +(a.league > b.league) || -(a.league < b.league))
    console.log(resultCards)
    const sheet = workbook.addWorksheet("対戦結果一覧")
    sheet.columns = [{ width: 25 }, { width: 3 }, { width: 25 } ]
    sheet.getColumn(1).style = { alignment: { horizontal: 'right' }, font: { color: { argb: 'FFFF00' } }, fill: { type: 'pattern', pattern:'solid', fgColor:{argb:'000000'} } }
    sheet.getColumn(2).style = { alignment: { horizontal: 'center' }, font: { color: { argb: 'FFFFFF' } }, fill: { type: 'pattern', pattern:'solid', fgColor:{argb:'000000'} } }
    sheet.getColumn(3).style = { alignment: { horizontal: 'left' }, font: { color: { argb: 'FFFFFF' } }, fill: { type: 'pattern', pattern:'solid', fgColor:{argb:'000000'} } }
    let currentLeague:string = ""
    for (const card of resultCards) {
        if (card.league !== currentLeague) {
            currentLeague = card.league
            const row = sheet.addRow([currentLeague, "", ""])
            sheet.mergeCells(row.number,1,row.number,3)
            sheet.getCell(row.number, 1).style = { alignment: { horizontal: 'center' }, font: { size:14, color: { argb: 'FFFFFF' } }, fill: { type: 'pattern', pattern:'solid', fgColor:{argb:'000000'} } }
        } 
        const row = sheet.addRow((card.score || 0) > (card.opponentScore || 0) ? [`${card.name}　${card.score || 0}`, "-", `${card.opponentScore || 0}　${card.opponentName}`] : [`${card.opponentName}　${card.opponentScore || 0}`, "-", `${card.score || 0}　${card.name}`])
        if ((card.score || 0) === (card.opponentScore || 0)) row.getCell(1).font = { color: { argb: 'FFFFFF' } }
    }



    const buff = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buff], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, file?.name.replace(/.*[/\\]/, '') || "");
    setMessages("ファイルへの書き込みが完了しました" + (sheetError ? "。 エラーになったデータがありますので確認してください" : ""))
}
