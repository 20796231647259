import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'
import Loading from './Loading'
import { PasswordInput } from './uiparts';



export default function SignIn({ register, signin, reset }) {
    const [mode, setMode] = useState("login");
    const toggleMode = () => {
        setMode((oldMode) => (oldMode === "login" ? "register" : "login"));
        setMessage("")
        setError("")
    };
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const handleChange = e => {
        setMessage("")
        setError("")
        if (e.target.name === "email") setEmail(e.target.value)
        if (e.target.name === "password") setPassword(e.target.value)
        if (e.target.name === "repeatPassword") setRepeatPassword(e.target.value)

    }
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [called, setCalled] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isProcessing) return null;
        setIsProcessing(true)
        if (mode === "login") {
            if (password.length < 8) {
                setError("パスワードは8文字以上必要です。");
            } else {
                if (parseInt(email) && email.indexOf("@") < 0) {// email is userId(teamId)
                    console.log(email)
                    let response = await fetch(`https://us-west-2.aws.data.mongodb-api.com/app/leagueapp-ofzll/endpoint/getemail?code=${email}`)
                    const data = await response.json()
                    console.log(data)
                    const derivedEmail = data.email
                    if (!derivedEmail) setError("このチームIDは存在しません。")
                    else {
                        const error = await signin(derivedEmail || "", password);
                        if (!error) return null
                        setError(error);
                    }
                 } else {
                    const error = await signin(email || "", password);
                    if (!error) return null
                    setError(error);
                }
            }
        } else {
            if (called) {
                alert("既に新規登録を受け付けています")
            } else if (email.length < 3) {
                setError('メールアドレスを入力してください。');
            } else if (password.length < 8) {
                setError("パスワードは8文字以上必要です。");
            } else if (password !== repeatPassword) {
                setError('再入力されたパスワードが一致しません。');
            } else {
                setCalled(true)
                const error = await register(email, password);
                if (error) {
                    setCalled(false)
                    setError(error);
                } else {
                    setMessage('入力されたメールアドレスに認証用リンクを送付しました。登録を完了するにはリンクをクリックしてください。')
                }
            }
        }
        setIsProcessing(false);
    }
    const handleResetPassword = async (e) => {
        e.preventDefault()
        if (isProcessing) return null;
        setIsProcessing(true)
        if (email.length < 3) {
            setError("メールアドレスを入力してください。");
        } else {
            const error = await reset(email)
            if (error) {
                setError(error)
            } else {
                setMessage(`パスワード変更リンクを送信しました。`)
            }
        }
        setIsProcessing(false);
    }

    return <>{isProcessing && <Loading modal />}
        <div className="h-full max-w-screen-sm w-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-6 w-6"
                        src={`${process.env.PUBLIC_URL}/favicon.ico`}
                        alt=""
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{mode === "login" ? "ログイン" : "新規登録"}</h2>
                </div>
                <form className="mt-6 space-y-6" onSubmit={handleSubmit} >
                    {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">
                                {mode === "register" ? "Email" : "チームID または Email"}
                            </label>
                            <input
                                id="email"
                                name="email"
                                type={mode === "register" ? "email" : "text"}
                                autoComplete="email-address"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                placeholder={mode === "register" ? "Email" : "チームID または Email"}
                                value={email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                パスワード
                            </label>
                            <PasswordInput mode={mode === "register" ? "" : "bottom"} value={password} onChange={handleChange} />
                        </div>
                        {mode === "register" &&
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    パスワード（再入力）
                                </label>
                                <input
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    autoComplete="current-password"
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                    placeholder="パスワード（再入力）"
                                    value={repeatPassword}
                                    onChange={handleChange}
                                />
                            </div>}
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-800 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-theme-500 group-hover:text-theme-400" aria-hidden="true" />
                            </span>
                            {mode === "login" ? "ログイン" : "登録"}
                        </button>
                    </div>
                </form>
                <div className="flex justify-between text-theme-800 hover:text-theme-600">
                    {mode === "login" ? <div className="cursor-pointer" onClick={handleResetPassword}>パスワード再設定</div> : <div></div>}
                    <div
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMode();
                        }}
                    >
                        {mode === "login" ? "新規登録" : "ログイン"}
                    </div>
                </div>
            </div>
        </div>
    </>
}