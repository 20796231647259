import { MouseEvent, useState } from "react";
import {
  DownloadIcon,
} from "@heroicons/react/solid";

import { properties as team } from "LeagueApp/data_sources/mongodb-atlas/League/team/schema.json";
import {
  DataList,
  ListItem,
  EditLinkTd,
  FilterSelect,
  FilterInput,
} from "Components/List";
import { ButtonClass } from "utils";
import { useAppContext } from "contexts/AppContext";
import options from "contexts/options.json";
const location = "/admin/team";

const getTitle = (obj: {
  bsonType?: string;
  title?: string;
  description?: string;
}) => obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0] || "";
const heads: { [key: string]: string }[] = [
  { text: "ステータス", field: "status" },
  { text: getTitle(team.teamId), field: "teamId" },
  { text: getTitle(team.league), field: "league" },
  { text: getTitle(team.name), field: "name" },
  { text: getTitle(team.place), field: "place" },
  { text: getTitle(team.repName), field: "repName" },
  { text: `${getTitle(team.tel)} / ${getTitle(team.email)}`, field: "tel" },
  { text: "", field: "" },
];

const Filter = (key:string, count: number, first:KV, last:KV, handleDownload: () => void) => {
  const appContext = useAppContext();
  const [, ] = useState(1);
  const filter = appContext.state.team?.filter || {};
  const [teamId, setTeamId] = useState(filter.teamId || "")
  const [name, setName] = useState(filter.name || "")
  const updateFilter = (key: string, value: any) => appContext.dispatch({
    team: { ...appContext.state.team, filter: { ...filter, [key]: value}, page:1 }
  });
  const handleBlur = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    let query: { [key: string]: string | number } = {...filter};
    if (e.target instanceof HTMLInputElement) {
      if (e.target.name === "teamId") query.teamId = parseInt(e.target.value) || 0;
      if (e.target.name === "name") query.name = e.target.value;
    }
    appContext.dispatch({
      team: { ...appContext.state.team, filter: query, page:1 },
    });
  };
  return (
    <>
      {filter && (<>
          <FilterSelect
            label="種別"
            name="role"
            value={filter.role || ""}
            option={options.role}
            setState={(v) => updateFilter("role", v)}
            onBlur={() => {}}
          />
          <FilterSelect
            label="階級"
            name="league"
            value={filter.league || ""}
            option={options.league}
            setState={(v) => updateFilter("league", v)}
            onBlur={() => {}}
          />
          <FilterSelect
            label="グループ"
            name="group"
            value={filter.group || ""}
            option={options.group}
            setState={(v) => updateFilter("group", v)}
            onBlur={() => {}}
          />
          <FilterInput
            label="チームID"
            name="teamId"
            value={teamId}
            setState={setTeamId}
            onBlur={handleBlur}
          />
          <FilterInput
            label="チーム名"
            name="name"
            value={name}
            setState={setName}
            onBlur={handleBlur}
          />

          <button
            className={ButtonClass.replace("m-3 py-2 px-4", "mx-3 my-0 p-1")}
            onClick={handleDownload}
          >
            <DownloadIcon className="h-4 w-4" />
            Excel
          </button>
        </>
      )}
    </>
  );
};

const row = ({
  datum,
  clickDelete,
}: {
  datum: KV;
  clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <>
    <ListItem data={[options.role[datum.role]]} badge />
    <ListItem data={[datum.teamId]} />
    <ListItem data={[datum.league]} />
    <ListItem data={[datum.name]} />
    <ListItem data={[datum.place]} />
    <td>
      <div className="text-xs">{`${datum.repName?.surnameKana || ""}　${
        datum.repName?.givenNameKana || ""
      }`}</div>
      <div className="text-gray-900">{`${datum.repName?.surname || ""}　${
        datum.repName?.givenName || ""
      }`}</div>
    </td>
    <ListItem data={[datum.tel, datum.email]} />
    <EditLinkTd
      location={location}
      id={datum._id}
    />
  </>
);

/*
const crash = async (teamId:number, league:string) => {
  const mongo = app.currentUser?.mongoClient("mongodb-atlas")
  const data = await mongo.db("League").collection("league").findOne({ _id:league })
  let teams = [...data.teams]
  const results = await mongo.db("League").collection("result").find({ $or: [{team:teamId}, {opponent:teamId}]})
  teams = teams.filter(team => !results.find((result:KV) => result.team === team || result.opponent === team))

  let cards: KV[] = []
  teams.forEach(team => {
    cards.push({ _id: nanoid(), date: JSTLastSunday(), team: teamId, opponent: id2, score:0, opponentScore:7, status:"confirmed" })
    cards.push({ _id: nanoid(), date: JSTLastSunday(), team: id2, opponent: teamId, score:7, opponentscore:0, status:"confirmed" })
  })
  const results = await mongo.db("League").collection("result").insertMany(cards)

}
*/

const List = () => {
  const download = (Data: KV[]) =>
    Data.map((v) => {
      console.log(v);
      return {
        チームID: v.teamId || "",
        チーム名: v.name,
        所属階級: v.league,
        代表者名: `${v.repName?.surname || ""} ${v.repName?.givenName || ""}`,
        電話: v.tel || "",
        メール: v.email || "",
      };
    });

  return (
    <DataList
      collection="team"
      location={location}
      heads={heads}
      filter={Filter}
      row={row}
      download={download}
      pagination
    />
  );
};

export default List;
