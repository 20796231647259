import { CheckCircleIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { FieldRadio } from "Components/uiparts";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ButtonClass } from "utils";
import options from "contexts/options.json";
import { Form, Formik, FormikProps } from "formik";
import { useRealmApp } from "RealmApp";
import { useAdd, useDelete, useUpdate } from "graphql/useCollection";
import { JSTNextSunday, JSTToday } from "contexts/dateUtils";
import { requestingTime } from "contexts/AppContext";

export const FormButton = ({ formik, completed = null }:{formik:FormikProps<any>, completed?:(() => void) | null}) => (
  <>
    <button type="submit" className={ButtonClass} disabled={formik.isSubmitting}>
      <CheckCircleIcon className="w-5 h-5" />
      送信
    </button>
    <Link to="/team">
      <button className={ButtonClass} disabled={formik.isSubmitting}>
        <ChevronLeftIcon className="w-5 h-5" />
        戻る
      </button>
    </Link>
  </>
);

const Page = () => {
  const { id } = useParams()
  const [ dataId, setDataId] = useState(id)
  const app = useRealmApp();
  const navigate = useNavigate();
  const addData = useAdd("request");
  const updateData = useUpdate("request")
  const deleteData = useDelete("request")
  const date = JSTNextSunday(JSTToday(), String(app.currentUser?.customData?.league || "").includes("土") ? -24 : 0);
  const isValid = requestingTime(String(app.currentUser?.customData?.league || ""))
  useEffect(() => { if (!isValid) navigate("/team") }, [isValid, navigate])
  return (!isValid ? <div className="p-2 text-center text-red-800">受付期間外です</div> : 
    <>
      <div className="p-4">
        <h3 className="text-lg text-theme-800 font-medium leading-6">
          試合申込フォーム
        </h3>
      </div>
      <div className="">
        <div className="shadow sm:rounded-md">
          <Formik
            initialValues={{
              date: date,
              type: "apply",
              team: { link:(app.currentUser?.customData?.teamId as {$numberInt:number}).$numberInt || (app.currentUser?.customData?.teamId as {$numberDouble:number}).$numberDouble || 0 },
              double: "false",
              createdAt: new Date(),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (!requestingTime(String(app.currentUser?.customData?.league || ""))) {  // Recheck at submit time
                alert("受付期間外です")
                setSubmitting(false);
                navigate("/team");
                return null
              }
              let updates:KV = { ...values };
              updates.double = updates.double === "true";
              updates.createdAt = new Date();
              updates.status = "submit"
              try {
                let dbError
                if (dataId) {
                  if (updates.type === "cancel")  {
                    const { error } = await deleteData(dataId)
                    dbError = error
                  } else {
                    delete updates.type
                    const { error } = await updateData({_id:dataId}, updates)
                    dbError = error
                  }
                } else {
                  delete updates.type
                  const { addedData, error } = await addData(updates);
                  setDataId(addedData._id)
                  dbError = error
                }
                if (dbError) {
                  if (dbError.message.indexOf("Duplicate key error") > -1) {
                    const key = dbError.message.match(/(?:dup\skey:\s{\s)\S*(?=:)/g)?.[0].replace(/(dup\skey:\s{\s)/g,"") || ""
                    if (["date", "team"].includes(key)) alert("エラー：\n既に受け付けられています。変更する場合は一度トップ画面に戻ってください");
                    else alert("エラー：\n少し時間を置いて再度確定ボタンをクリックしてください");
                  } else {
                    alert("データベースエラー：\n" + dbError);
                  }
                }
                setSubmitting(false);
                if (!dbError) navigate("/team");
              } catch (e:any) {
                if (e.message.indexOf("Duplicate key error") > -1) {
                  const key = e.message.match(/(?:dup\skey:\s{\s)\S*(?=:)/g)?.[0].replace(/(dup\skey:\s{\s)/g,"") || ""
                  if (["date", "team"].includes(key)) alert("エラー：\n既に受け付けられています。変更する場合は一度トップ画面に戻ってください");
                  else alert("エラー：\n少し時間を置いて再度確定ボタンをクリックしてください");
                } else {
                  alert("エラー：\n" + e.message);
                }
                setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="p-2 bg-white space-y-6 sm:p-6">
                  <div className="border-b border-theme-100">
                    {date.getFullYear()}年{" "}
                    <span className="text-3xl text-theme-800">
                      {date.getMonth() + 1}
                    </span>{" "}
                    月{" "}
                    <span className="text-3xl text-theme-800">
                      {date.getDate()}
                    </span>{" "}
                    日 (
                    <span className={`${date.getDay() ? "text-blue-500" : "text-red-500"}`}>
                      {options.weekday[String(date.getDay())]}
                    </span>
                    ){" "}
                  </div>
                  <FieldRadio
                    label="申請種別"
                    name="type"
                    options={dataId ? [{name:"apply", label:"申し込む"}, {name:"cancel", label:"取り消す"}]:[{name:"apply", label:"申し込む"}]}
                    props={formik.getFieldProps("type")}
                    setFieldValue={formik.setFieldValue}
                  />
                  <FieldRadio
                    label="ダブルヘッダー"
                    name="double"
                    options={[{name:"true", label:"希望する"}, {name:"false", label:"希望しない"}]}
                    props={formik.getFieldProps("double")}
                    setFieldValue={formik.setFieldValue}
                  />
                  <div className="text-xs">※なお、ダブルヘッダーを希望されても、相手チームの都合等の関係で、希望通り組めない場合がありますので、予めご了承ください</div>
                </div>
                <div className="px-4 bg-gray-50 text-right sm:px-6">
                  <FormButton formik={formik}/>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Page;
