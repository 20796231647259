/*
  Copyright ©︎ 2021 Kiyofumi Ikoma @ K Itelligence
  - All rights reserved.
  This file is granted for use in yaangisawa project.
  Do not use without prior written permission.
*/

const Loading = ({modal}:{modal?:boolean}) => {
    const circleCommonClasses: string = "h-2.5 w-2.5 bg-current rounded-full";

    return (<>{ modal ?  
        <div className="fixed z-50 left-0 top-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-24 h-24 flex justify-center items-center border-2 rounded-full">
                <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                <div className={`${circleCommonClasses} animate-bounce400`}></div>
            </div>
        </div>
        :
        <div className="flex w-full h-full min-h-32 justify-center transform translate-y-1/2">
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
        </div>
    }</>);
}

export default Loading