//import Holidays from 'date-holidays'

//const hd = new Holidays('JP')
const Locale = 'ja-JP'
const Time_Zone = 'Asia/Tokyo'
export const Offset_Hours = 9
export const currentOffset = new Date().getTimezoneOffset()*60000
export const Oneday_ms = 86400000
export const JA_Timezone_Offset = Offset_Hours * 3600000
export const offset = currentOffset + JA_Timezone_Offset

export const isLocaleValid = (locale = Locale, timezone = Time_Zone) => {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        return false;
    }
    try {
        Intl.DateTimeFormat(locale, {timeZone: timezone});
        return true;
    }
    catch (ex) {
        return false;
    }
}
 
/**
 * Add offset hours if UTC date and JST date is different(3pm or later).  Works at any timezone.
 * @param {Date} date Date to add offset.  Any timezone is OK
 * @returns {Date} New date object which offset hours are added to
 */
const UTCtoJSTDate = (date:Date) => date.getUTCHours() >= (24-Offset_Hours) ? new Date(date.getTime() + JA_Timezone_Offset) : new Date(date)
const weekdays = ['日', '月', '火', '水', '木', '金', '土', '日']


export const JSTWeekend = (date:Date) => ['土', '日'].includes(JSTWeekday(date))
//export const JSTPublicHoliday = (date:Date) => Boolean((hd.isHoliday(date) || []).find(holiday => holiday.type === 'public'))
//export const isWEPH = (date:Date) => JSTWeekend(date) || JSTPublicHoliday(date)

export const JSTDate = (date:Date) => date.toLocaleDateString(Locale, { timeZone: Time_Zone})
export const JSTMonthDate = (date:Date) => date.toLocaleDateString(Locale, { timeZone: Time_Zone, month: 'short', day: 'numeric'})
export const JSTMonthDateString = (date:Date) => `${date.getMonth()+1}月${date.getDate()}日`
export const JSTMonthDateStringWithWeekday = (date:Date) => `${date.getMonth()+1}月${date.getDate()}日（${weekdays[date.getDay()]}）`
export const JSTTime = (date:Date) => date.toLocaleTimeString(Locale, { timeZone: Time_Zone, hour:'numeric', minute:'numeric'})
export const JSTDateTime = (date:Date) => date.toLocaleString(Locale, { timeZone: Time_Zone, year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric'})
export const JSTYearMonth = (date:Date) => date.toLocaleDateString(Locale, { timeZone: Time_Zone, year: 'numeric', month: 'short'})

export const JSTYear = (date:Date = new Date()) => UTCtoJSTDate(date).getUTCFullYear()
export const JSTMonth = (date:Date = new Date()) => UTCtoJSTDate(date).getUTCMonth() + 1
export const JSTDay = (date:Date = new Date()) => UTCtoJSTDate(date).getUTCDate()
export const JSTHour = (date:Date = new Date()) => {
    let hours = date.getUTCHours() + Offset_Hours
    return hours > 24 ? hours - 24 : hours
}
/**
 * Retuerns day of week at JST.
 * @param {Date?} date Date to get day of week 
 * @returns {string} Day of week as Japanese string
 */
export const JSTWeekday = (date:Date = new Date()) => weekdays[JSTWeekdayNumber(date)]
/**
 * Retuerns day of week at JST.
 * @param {Date?} date Date to get day of week 
 * @returns {number} Day of week as number
 */
 export const JSTWeekdayNumber = (date:Date = new Date()) => UTCtoJSTDate(date).getUTCDay()
 //export const JSTWeekdayHoliday = (date:Date) => JSTPublicHoliday(date) ? '祝' : JSTWeekday(date)

// get today with JST hour or JST midnight
export const JSTToday = (hour:number = 0) => new Date(UTCtoJSTDate(new Date()).setUTCHours(hour,0,0,0) - JA_Timezone_Offset)

/**
 * Returns new Date object
 * @param {number} year 
 * @param {number} month Month between 1-12 
 * @param {number} date 
 * @param {number?} hour JST hour 
 * @param {number?} minute 
 * @param {number?} second 
 * @returns {Date}
 */
export const JSTNewDate = (year:number, month:number, date:number, hour:number = 0, minute:number = 0, second:number = 0) => new Date(Date.UTC(year, month-1, date, hour - Offset_Hours, minute, second))


/**
 * get next sunday JST hour or JST midnight.  For fridays and saturdays, get after next sunday.
 * @param {number?} hour JST hour to set
 * @returns {Date} Next sunday
 */
export const JSTNextSunday = (date:Date | undefined = undefined, hour:number = 0) => {
    let actualDate = date ? setJSTHour(date, 0) : JSTToday()
    const weekday = JSTWeekdayNumber(actualDate)
    return new Date(actualDate.getTime() + 86400000 * ((weekday > 4 ? 14 : 7) - weekday) + 3600000 * hour)
}
// get last sunday(or same day for sundays) JST midnight.  For saturdays, get next sunday(tomorrow).
export const JSTLastSunday = (date:Date | undefined = undefined, hour:number = 0) => {
    let actualDate = date ? setJSTHour(date, 0) : JSTToday()
    const weekday = JSTWeekdayNumber(actualDate)
    return new Date(actualDate.getTime() - 86400000 * (weekday - (weekday > 5 ? 7 : 0)) + 3600000 * hour)
}
export const setJSTHour = (date:Date, hour:number, minute:number = 0) => 
    new Date(UTCtoJSTDate(date).setUTCHours(hour - Offset_Hours, minute, 0, 0))

export const setUTCHour = (date:Date, hour:number, minute:number = 0) => 
    new Date(UTCtoJSTDate(date).setUTCHours(hour, minute, 0, 0))

export const getLastMatchDate = () => {
    let date = new Date();
    date.setUTCHours(-9, 0, 0, 0);// Today midnight
    if (date.getDay() === 6) date.setDate(date.getDate() + 1)
    else date.setDate(date.getDate() - date.getDay());// Local time base last Sunday()
    return date;
};
