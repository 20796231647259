import { useState } from 'react';
import { FieldPlain } from 'Components/uiparts.tsx'
import DialogFrame from "Components/Dialog"
import Loading from "Components/Loading"
import { useRealmApp } from 'RealmApp';

const TeamSearch = ({field, show, setShow, formik}) => {
    const app = useRealmApp()
    const mongo = app.currentUser?.mongoClient("mongodb-atlas")
    const [teamId, setTeamId] = useState("")
    const [name, setName] = useState("")
    const [tel, setTel] = useState("")
//    const { getData, loading, Data } = useLazyData("team")
    const [loading, setLoading] = useState(false)
    const [Data, setData] = useState()
    const handleKeyUp = (e) => {
        e.which = e.which || e.keyCode;
        if (e.which === 13) {
            executeQuery()
        }
    }
    const executeQuery = async e => {
        let query = {}
        const tid = (e && (e.target.name === "teamId")) ? (e.target.value === "false" ? false : e.target.value) : teamId
        const tname = (e && (e.target.name === "name")) ? (e.target.value === "false" ? false : e.target.value) : name
        const ttel = (e && (e.target.name === "tel")) ? (e.target.value === "false" ? false : e.target.value) : tel
        if (tid && parseInt(tid)) query.teamId = parseInt(tid)
        if (tname) query.name = { $regex:tname }
        if (ttel) query.tel = ttel
        if (!query.teamId && !query.name && !query.tel) return
        setLoading(true)
        const data = await mongo.db("League").collection("team").find(query)
        setLoading(false)
        setData(data)
//        getData({ variables: { query: query}})
    }

    const handleClick = data => {
        const udpate = { ...data }
        delete udpate.__typename
        if (field === "teams") formik.values.teams = [...(formik.values.teams || []), data]
        else formik.values[field] = data
        setShow(false)
    }

    return (<DialogFrame show={show} setShow={setShow}>
        <div className="grid grid-cols-6 gap-6">
            <FieldPlain label="チームID" name="teamId" props={{ value: teamId, onChange: e => setTeamId(e.target.value), onKeyUp: handleKeyUp, onBlur: executeQuery  }} span="2" />
            <FieldPlain label="チーム名" name="name" props={{ value: name, onChange: e => setName(e.target.value), onKeyUp: handleKeyUp, onBlur: executeQuery  }} span="2" />
            <FieldPlain label="TEL" name="tel" props={{ value: tel, onChange: e => setTel(e.target.value), onKeyUp: handleKeyUp, onBlur: executeQuery }} span="2" />
        </div>
        {Data && (<div className="container flex flex-col mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
            {loading && <div className="m-4"><Loading /></div>}
            <table>
                <tbody>
                { Data.map(data => (
                    <tr key={data._id} className="select-none cursor-pointer border" value={data._id} onClick={e => handleClick(data)}>
                        <td className="p-2 text-gray-600 dark:text-gray-200 max-w-xs overflow-hidden">
                            {data.teamId}
                        </td>
                        <td className="p-2 text-gray-600 dark:text-gray-200 max-w-xs overflow-hidden">
                            {data.league}
                        </td>
                        <td className="p-2 text-gray-600 dark:text-gray-200 max-w-xs overflow-hidden">
                            {data.name}
                        </td>
                        <td className="p-2 text-gray-600 dark:text-gray-200 max-w-xs overflow-hidden">
                            {data.tel}
                        </td>
                        <td>
                        <button className="w-18 text-right flex justify-end">
                            <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                </path>
                            </svg>
                        </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>)}
    </DialogFrame>)
}

export default TeamSearch