import { CheckCircleIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { FieldNumber, FieldSelect } from "Components/uiparts";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ButtonClass } from "utils";
import options from "contexts/options.json";
import { Form, Formik, FormikProps } from "formik";
import { useRealmApp } from "RealmApp";
import { useData, useUpdate } from "graphql/useCollection";
import { getError } from "Components/edit";
import { JSTLastSunday, JSTToday } from "contexts/dateUtils";
import Loading from "Components/Loading";
import { resultingTime } from "contexts/AppContext";

export const FormButton = ({ formik, completed = null }:{formik:FormikProps<any>, completed?:(() => void) | null}) => (
  <>
    <button type="submit" className={ButtonClass} disabled={formik.isSubmitting}>
      <CheckCircleIcon className="w-5 h-5" />
      送信
    </button>
    <Link to="/team">
      <button className={ButtonClass} disabled={formik.isSubmitting}>
        <ChevronLeftIcon className="w-5 h-5" />
        戻る
      </button>
    </Link>
  </>
);


const Page = () => {
  const app = useRealmApp();
  const navigate = useNavigate();
  const date = JSTLastSunday(JSTToday(), String(app.currentUser?.customData?.league || "").includes("土") ? -24 : 0);
  const { loading, Data } = useData("result", { "team":{"_id": app.currentUser?.id}, date:date })
  const opponentOptions = Data.reduce((a, r) => ({...a, [r.opponent.teamId]:r.opponent.name}), {"":"未選択"})
  const updateData = useUpdate("result");
  const isValid = resultingTime(String(app.currentUser?.customData?.league || ""))
  useEffect(() => { if (!isValid) navigate("/team") }, [isValid, navigate])
  if (loading || !isValid) return <Loading />
  return (!Data.length ? <div className="flex flex-col items-center">
      <div className="p-8 font-bold text-red-600">報告対象となる試合はありません</div>
      <Link to="/team">
        <button className={ButtonClass}>
          <ChevronLeftIcon className="w-5 h-5" />
          戻る
        </button>
      </Link>
    </div>
     : 
    <>
      <div className="md:col-span-1">
        <div className="p-4">
          <h3 className="text-lg text-theme-800 font-medium leading-6">
            試合結果報告フォーム
          </h3>
        </div>
      </div>
      <div className="">
        <div className="shadow sm:rounded-md">
          <Formik
            initialValues={{
              date: date,
              score: "",
              opponent: "",
              opponentScore: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (!resultingTime(String(app.currentUser?.customData?.league || ""))) {
                alert("受付期間外です")
                setSubmitting(false);
                navigate("/team");
                return null
              }
              if (!values.opponent) {
                alert("相手チームを選択してください")
                return null
              }
              if ((!values.score && String(values.score) !== "0")|| (!values.opponentScore && String(values.opponentScore) !== "0")) {
                alert("得点を入力してください")
                return null
              }
              const content = `${
                values.score > values.opponentScore ? "○" : values.score < values.opponentScore ? "✕" : "△"
              }${
                app.currentUser?.customData?.name || ""
              }　${
                values.score || (String(values.score) === "0" ? "0" : "")
              }\n${
                values.score < values.opponentScore ? "○" : values.score > values.opponentScore ? "✕" : "△"
              }${
                opponentOptions[values.opponent]
              }　${
                values.opponentScore || (String(values.opponentScore) === "0" ? "0" : "")
              }\nで送信してよろしいですか？`
              if (!window.confirm(content)) return null
              const query = { _id: Data.find(data => data.opponent.teamId === parseInt(values.opponent))?._id || Data[0]._id }
              let updates:KV = { 
                score: values.score,
                opponentScore: values.opponentScore,
                modifiedAt: new Date(),
                status: "submit"
              };
              try {
                const { error } = await updateData(query, updates);
                if (error) alert("データベースエラー：\n" + error);
                setSubmitting(false);
                if (!error) navigate("/team");
              } catch (e: any) {
                alert("エラー：\n" + e.message);
                setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="p-2 bg-white space-y-6 sm:p-6">
                  <div className="border-b border-theme-100">
                    {date.getFullYear()}年{" "}
                    <span className="text-3xl text-theme-800">
                      {date.getMonth() + 1}
                    </span>{" "}
                    月{" "}
                    <span className="text-3xl text-theme-800">
                      {date.getDate()}
                    </span>{" "}
                    日 (
                      <span className={`${date.getDay() ? "text-blue-500" : "text-red-500"}`}>
                      {options.weekday[String(date.getDay())]}
                    </span>
                    ){" "}
                  </div>
                  <ul className="p-2 rounded border border-gray-300">
                    <span className="text-theme-800">報告(送信)済の試合結果</span>
                    {Data.filter(datum => datum.status === "submit").map(datum => <li>
                      {`${app.currentUser?.customData?.name || ""}　${datum.score || (String(datum.score) === "0" ? "0" : "")} - ${datum.opponentScore || (String(datum.opponentScore) === "0" ? "0" : "")}　${datum.opponent.name}`}
                    </li>)}
                  </ul>
                  <FieldNumber
                    label="自チーム得点"
                    name="score"
                    props={formik.getFieldProps("score")}
                    error={String(getError(formik, "score") || "")}
                  />
                  <FieldSelect
                    label="相手チーム"
                    name="opponent"
                    options={opponentOptions}
                    props={formik.getFieldProps("opponent")}
                  />
                  <FieldNumber
                    label="相手チーム得点"
                    name="opponentScore"
                    props={formik.getFieldProps("opponentScore")}
                    error={String(getError(formik, "opponentScore") || "")}
                  />
                </div>
                <div className="p-4 bg-gray-50">{`${app.currentUser?.customData?.name || ""}　${formik.values.score || (String(formik.values.score) === "0" ? "0" : "")} - ${formik.values.opponentScore || (String(formik.values.opponentScore) === "0" ? "0" : "")}　${opponentOptions[formik.values.opponent]}`}</div>
                <div className="px-4 bg-gray-50 text-right sm:px-6">
                  <FormButton formik={formik}/>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Page;
