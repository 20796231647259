import { MouseEvent, useState } from 'react'
import * as Yup from "yup";
import { PlusIcon } from "@heroicons/react/solid";
import { properties as schema } from "LeagueApp/data_sources/mongodb-atlas/League/league/schema.json";
import relations from "LeagueApp/data_sources/mongodb-atlas/League/league/relationships.json";
import { SimpleListFrame, ListItem, EditLinkTd, UpDownTd } from "Components/List"
import {
  getSchemaValues,
  CreateBase,
  EditBase,
  FormFrame,
  FieldBase,
} from "Components/edit";
import TeamSearch from '../team/TeamSearch';
import { FormikProps } from 'formik';

const heads = ["チームID", "チーム名", "所在地", "代表者名", "電話番号", "", "", ""]

const FieldArray = ({
  schema,
  formik,
  name,
  parent,
  showItem,
  setShowItem,
}: {
  schema: KV;
  formik: FormikProps<any>;
  name: string;
  parent?: string;
  showItem: number | null;
  setShowItem: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const fullName = (parent ? parent + "." : "") + name
  const [, setItemCount] = useState(-1)
  const handleDeleteClick = (e: MouseEvent, i: number) => {
    formik.values[fullName].splice(i, 1)
    setItemCount(formik.values[fullName].length)
  }
  const handleUpDownClick = (e: MouseEvent<HTMLButtonElement>, i: number) => {
    if (e.currentTarget.name === "up" && i) {
      let newArray = [...formik.values[fullName]]
      const temp = newArray[i-1]
      newArray[i-1] = newArray[i]
      newArray[i] = temp
      formik.values[fullName] = newArray
    } else if (e.currentTarget.name === "down" && formik.values[fullName].length -1 > i) {
      let newArray = [...formik.values[fullName]]
      const temp = newArray[i+1]
      newArray[i+1] = newArray[i]
      newArray[i] = temp
      formik.values[fullName] = newArray
    }
  }
  console.log(formik.values[fullName])
  return (<>
    <SimpleListFrame heads={heads} noAdd>
      {(formik.values[fullName] || []).map(
        (datum: KV, i: number) => (
          <tr key={`${fullName}${i}`} className="text-sm text-gray-900">
            <ListItem data={[`${datum.teamId}`]} />
            <ListItem data={[`${datum.name}`]} />
            <ListItem data={[`${datum.place}`]} />
            <ListItem data={[`${datum.repName?.surname || ""} ${datum.repName?.givenName || ""}`]} />
            <ListItem data={[`${datum.tel}`]} />
            <UpDownTd
              onClick={(e) => handleUpDownClick(e, i)}
            />
            <EditLinkTd
              id={String(i)}
              noEdit
              onClickDelete={(e) => handleDeleteClick(e, i)}
            />
          </tr>
        )
      )}
    </SimpleListFrame>
    <TeamSearch field="teams" show={Boolean(showItem) || showItem === 0} setShow={setShowItem} formik={formik} />

  </>)
}


const FormFields = ({ formik }: { formik: FormikProps<any>; }) => {
  // firewoodItem input dialog (items array index)
  const [showItemInput, setShowItemInput] = useState<number | null>(null)
  // handling add firewoodItem button. adding new firewoodItem
  const handleAddItemClick = (e: MouseEvent) => {
    const count = formik.values.teams?.length || 0
    setShowItemInput(count)
  }

  return (
    <FormFrame formik={formik} schema={schema}>
      <FieldBase name="league" />
      <FieldBase name="group" />
      <FieldBase name="weekday" />
      <div className="relative">
        <div className="flex items-center">
          <label className="text-sm font-medium text-gray-700">所属チーム</label>
          <button type="button" onClick={handleAddItemClick} className="ml-4 flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>
        </div>
        <div className="-mx-4"><FieldArray name="teams" showItem={showItemInput} setShowItem={setShowItemInput} schema={schema} formik={formik} /></div>
      </div>
    </FormFrame>
  )
};

const validationSchema = Yup.object({
  repName: Yup.object({
    surname: Yup.string().max(15, "Must be 15 characters or less"),
    surnameKana: Yup.string().max(15, "Must be 15 characters or less"),
    givenName: Yup.string().max(20, "Must be 20 characters or less"),
  }),
  email: Yup.string().email("無効なメールアドレスです"),
});

// Delete custom resolver field assignedTeams
const writePrepare = (updates: any) => {
  if (!updates._id) updates._id = updates.league + updates.group
};

export const Create = () => {
  return (<CreateBase
    title="リーグ情報"
    values={getSchemaValues(schema)}
    collection="league"
    relations={relations}
    location="/admin/league"
    Fields={FormFields}
    writePrepare={writePrepare}
    validation={validationSchema}
  />);
};

export const Edit = () => {
  return (
    <EditBase
      title="リーグ情報"
      values={getSchemaValues(schema)}
      collection="league"
      relations={relations}
      location="/admin/league"
      Fields={FormFields}
      writePrepare={writePrepare}
      validation={validationSchema}
    />
  );
};
export default Edit;
