import React from 'react'
import { Tab } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { update, updateEmail, deleteUser, useRealmApp } from 'RealmApp'
import { PasswordInput } from './uiparts'

export default function Account() {
    const app = useRealmApp()
    const [currentPassword, setCurrentPassword] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [repeatEmail, setRepeatEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [repeatPassword, setRepeatPassword] = React.useState('')
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [message, setMessage] = React.useState(selectedTab ? "新しいメールアドレスを入力してください" : "新しいパスワードを入力してください");
    const [error, setError] = React.useState("");
    const [sent, setSent] = React.useState(false)
    const resetInput = () => {
        setEmail('')
        setRepeatEmail('')
        setPassword('')
        setRepeatPassword('')
    }
    const handleTabChange = (index) => {
        setSelectedTab(index)
        setMessage(index ? "新しいメールアドレスを入力してください" : "新しいパスワードを入力してください")
    }

    const handleChange = e => {
        setMessage(selectedTab ? "新しいメールアドレスを入力してください" : "新しいパスワードを入力してください")
        setError("")
        if (e.target.name === "currentPassword") setCurrentPassword(e.target.value)
        if (e.target.name === "email") setEmail(e.target.value)
        if (e.target.name === "repeatEmail") setRepeatEmail(e.target.value)
        if (e.target.name === "password") setPassword(e.target.value)
        if (e.target.name === "repeatPassword") setRepeatPassword(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        setMessage("")
        setError("")
        switch (selectedTab) {
            case 0:
                if (currentPassword.length < 8) setError("パスワードは8文字以上必要です。")
                else if (password !== repeatPassword) setError("再入力パスワードが一致しません。")
                else if (password.length < 8) setError("パスワードは8文字以上必要です。")
                else {
                    const error = await update(currentPassword, password)
                    if (error) setError(error)
                    else setMessage("パスワードを更新しました")
                }
                break;
            case 1:
                if (sent) alert("既にメールアドレス変更を受け付けています")
                else if (currentPassword.length < 8) setError("パスワードは8文字以上必要です。")
                else if (email !== repeatEmail) setError("再入力メールアドレスが一致しません。")
                else if (email.length < 3) setError("メールアドレスを入力してください。")
                else {
                    const error = await updateEmail(currentPassword, email)
                    if (error) setError(error)
                    else {
                        setSent(true)
                        setMessage("メールアドレスを変更しました。変更後のメールアドレスに認証用リンクを送付しましたので、リンクをクリックしてください。")
                    }
                }
                break;
            case 2:
                const error = await deleteUser(app)
                if (error) setError(error)
                break;
            default:

        }
        resetInput()
        setIsProcessing(false)
        return false
    }
    return (
        <div className="h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">ログイン情報変更</h2>
                <form className="mt-8 space-y-4" onSubmit={handleSubmit} >
                <Tab.Group onChange={handleTabChange}>
                    <Tab.List className="flex p-1 space-x-1 bg-blue-800 rounded-xl">
                            {["パスワード", "メール"].map((category) => <Tab
                            key={category}
                            className={({ selected }) =>
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg ' + 
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 ' + 
                                    (selected
                                    ? 'text-blue-700 bg-white shadow'
                                        : 'text-blue-100 hover:bg-blue-600 hover:text-white')
                                }
                            >
                                {category}
                            </Tab>)}
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                                <div className="rounded-md shadow-sm -space-y-px min-h-44">
                                    {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                                    <div>
                                        <label htmlFor="currentPassword" className="sr-only">
                                            現在のパスワード
                                        </label>
                                        <input
                                            id="currentPassword"
                                            name="currentPassword"
                                            type="password"
                                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                            placeholder="現在のパスワード"
                                            value={currentPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="sr-only">
                                            新パスワード
                                        </label>
                                        <PasswordInput value={password} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label htmlFor="repeatPassword" className="sr-only">
                                            パスワード（再入力）
                                        </label>
                                        <input
                                            id="repeatPassword"
                                            name="repeatPassword"
                                            type="password"
                                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                            placeholder="パスワード（再入力）"
                                            value={repeatPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                        </Tab.Panel>
                        <Tab.Panel>
                                <div className="rounded-md shadow-sm -space-y-px min-h-44">
                                    {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                                    <div>
                                        <label htmlFor="currentPassword" className="sr-only">
                                            現在のパスワード
                                        </label>
                                        <input
                                            id="currentPassword"
                                            name="currentPassword"
                                            type="password"
                                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                            placeholder="現在のパスワード"
                                            value={currentPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="sr-only">
                                            メールアドレス
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email-address"
                                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                            placeholder="メールアドレス"
                                            value={email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="repeatEmail" className="sr-only">
                                            メールアドレス（再入力）
                                        </label>
                                        <input
                                            id="repeatEmail"
                                            name="repeatEmail"
                                            type="email"
                                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                            placeholder="メールアドレス（再入力）"
                                            value={repeatEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="m-2 text-red-800 font-medium">アカウントを削除します</div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-600 disabled:bg-theme-400 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                            disabled={isProcessing}
                        >
                            <span className="flex items-center pr-1">
                                <CheckCircleIcon className="h-5 w-5 text-theme-100 group-hover:text-theme-400" aria-hidden="true" />
                            </span>
                            確定
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}