import React, { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import ExcelJS from 'exceljs'
import { ReadableWebToNodeStream } from 'readable-web-to-node-stream'
import { saveAs } from 'file-saver';
import { Stream } from 'stream';
import { ButtonClass } from 'utils';
import DatePicker from 'Components/datepicker';
import { JSTNextSunday, setUTCHour } from 'contexts/dateUtils';
import { nanoid } from 'graphql/useCollection';
import { useRealmApp } from 'RealmApp';
import { writeResultFile } from './results';
import { writeLastSummaryFile, writeSummaryFile } from './rankings';
//import { getTypenameFromResult } from '@apollo/client/utilities';

//    await workbook.xlsx.readFile(template_filename);
/**
 * Get Excel cell by cell name within worksheet. First 10 rows and 20 columns will be searched.
 * @param {ExcelJS.Worksheet} worksheet Worksheet to find cell
 * @param {string} name Name of cell to find.  Matching starting letters.
 * @returns {ExcelJS.Cell?} Cell
 */
export const getCellByName = (worksheet: ExcelJS.Worksheet, name: string) => {
    let match: ExcelJS.Cell | undefined
    let i = 1
    while (!match && i < 11) {
        const row = worksheet.findRow(i++)
        if (!row) continue
        let j = 1
        while (!match && j < 21) {
            const cell = row.findCell(j++)
            if (!cell) continue
            if (cell.names.find(n => n.startsWith(name))) match = cell
        }
    }
    return match
}

/**
 * Write requests to Excel file.
 * Read from database using Realm find
 * Set cell name "date*" somewhere on a row(<10) including date for each column.
 * Set cell name "teamid*" somewhere on a column(<20) including teamid.
 * @param {Date} date Date to process(Sunday)
 * @param {File} file Excel file to write request data. 
 * @param {Realm.Services.MongoDB} mongo MongoDB client. 
 * @param {function(string, boolean?, boolean?):void} setMessages Message dispatcher. 
 * @returns {void}
 */
const writeRequestFile = async (date:Date, file: File, functions: (Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory), mongo:Realm.Services.MongoDB, setMessages: (message: string, error?: boolean, add?: boolean) => void) => {
    const dates = [date, new Date(date.getTime() - 86400000)]
    // Read Excel file
    const stream = new ReadableWebToNodeStream(file.stream());
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(stream as unknown as Stream)

    // Find date and teamid cell for each sheet
    let daterows: { [key: number]: number } = {}
    let idcols: { [key: number]: number } = {}
    let noCellname = false
    workbook.eachSheet((worksheet, sheetId) => {
        const [datecell, idcell] = [getCellByName(worksheet, 'date'), getCellByName(worksheet, 'teamid')]
        if (!datecell || !idcell) {
            setMessages(`${worksheet.name}で日付またはチームIDのセルに名前がありません`, true)
            noCellname = true
            return null
        }
        daterows[sheetId] = datecell.fullAddress.row
        idcols[sheetId] = idcell.fullAddress.col
    })
    if (noCellname) return null

    let requests: KV[] = []
    try {
        requests = await mongo.db("League").collection("request").find({ date: { $in: dates } })
    } catch (e) {
        console.log(e)
        setMessages("データベースからデータの読込中にエラーが発生しました", true)
        return null
    }

    
    let cellNotFound = false
    for (const request of requests) {
        let found = false
        const findingDate = setUTCHour(new Date(request.date), 0).getTime()
        const findingId = Number(request.team || 0)
        workbook.eachSheet((worksheet, sheetId) => {
            const rowNum = worksheet.getColumn(idcols[sheetId]).values.indexOf(findingId)
            const colNum = (worksheet.getRow(daterows[sheetId]).values as Array<ExcelJS.CellValue>).findIndex((v: any) => v instanceof Date && v.getTime() === findingDate)
            if (rowNum > -1 && colNum > -1) {
                worksheet.getCell(rowNum, colNum).value = `◯${request.double ? "ダブル希望" : ""} ${request.remarks || ""}`
                found = true
            }
        })
        if (!found) {
            cellNotFound = true
            console.log(found)
            setMessages(`日付またはチームが見つかりません。チーム:${findingId}　${request.team.name}`, true, true)
        }
    }
    setMessages("集計が完了しました")
    const buff = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buff], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, file?.name.replace(/.*[/\\]/, '') || "");
    setMessages("ファイルへの書き込みが完了しました" + (cellNotFound ? "。書き込めなかったデータがありますので確認してください" : ""))
}

/*const LOAD_OPTIONS = gql`
  query($query:TeamQueryInput!) {
    teams(query:$query) {
      _id
      userId
      name
    }
  }
`;
*/
/**
 * Read match from Excel file.
 * Read from database by Realm find. Write by using Realm updateMany, deleteMany.  Write by using Mongo insertMany ordered:false
 * Set cell name "score*" somewhere on a column(<20) including score.
 * Set cell name "teamid1*", "teamid2" somewhere on a column(<20) including teamid.  Other values should not exist on these columns
 * @param {Date} date Date to process(Sunday)
 * @param {File} file Excel file to write result data. 
 * @param {(Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory)} functions Realm functions. 
 * @param {Realm.Services.MongoDB} mongo MongoDB client. 
 * @param {function(string, boolean?, boolean?):void} setMessages Message dispatcher. 
 * @returns {void}
 */
const readMatchFile = async (date: Date, file: File, functions: (Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory), mongo: Realm.Services.MongoDB, setMessages: (message: string, error?: boolean, add?: boolean) => void) => {
    const dates = [date, new Date(date.getTime() - 86400000)]
    // Read Excel file
    const stream = new ReadableWebToNodeStream(file.stream());
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(stream as unknown as Stream)
    const requests = await mongo.db("League").collection("request").find({date: { $in: dates}, status: { $exists: true}}, { projection: { team:1, status:1}})
    const requestTeams = requests.map(request => request.team)
    /** @type {{[key:number]:{[key:string]:number}}} Match Card objects for each team.  Key is teamid */
    let cards: KV[] = []
    workbook.eachSheet((worksheet, sheetId) => {
        const [team1cell, team2cell] = [getCellByName(worksheet, 'teamid1'), getCellByName(worksheet, 'teamid2')]
        if (!team1cell || !team2cell) {
            setMessages(`${worksheet.name}でチームIDのセルに名前がありません`, true)
            return null
        }
        const col1 = team1cell.fullAddress.col
        const col2 = team2cell.fullAddress.col
        const actualDate = dates[worksheet.name.includes("土曜") ? 1 : 0]
        worksheet.eachRow(row => {
            const id1 = Number(row.getCell(col1).value)
            const id2 = Number(row.getCell(col2).value)
            if (id1 && id2) {
                if (!requestTeams.includes(id1)) {
                    setMessages(`チーム:${id1} は試合を申し込んでいません`, true)
                    return null
                }
                if (!requestTeams.includes(id2)) {
                    setMessages(`チーム:${id2} は試合を申し込んでいません`, true)
                    return null
                }
                cards.push({ _id: nanoid(), date: actualDate, team: id1, opponent: id2 })
                cards.push({ _id: nanoid(), date: actualDate, team: id2, opponent: id1 })
            }
        })
    })
    setMessages("読み込みが完了しました")
    
    const okIds =  Array.from(new Set(cards.map(card => card.team)))
    try {
        await mongo.db("League").collection("request").updateMany({ date: { $in: dates}, team: { $in: okIds } }, { $set: { status: "confirmed" } })
        await mongo.db("League").collection("request").updateMany({ date: { $in: dates}, team: { $nin: okIds } }, { $set: { status: "no" } })
    } catch (e) {
        setMessages("データベースへの申込結果の書込中にエラーが発生しました", true)
        return null
    }
    let writtenCards: any[] = []
    try {
        writtenCards = await mongo.db("League").collection("result").find({ date: { $in: dates } })
    } catch (e) {
        setMessages("データベースからの既存データの読込中にエラーが発生しました", true)
        return null
    }
    const writingCards = cards.filter(card => !writtenCards.find(writtenCard => writtenCard.team === card.team && writtenCard.opponent === card.opponent))
    const removingCards = writtenCards.filter(writtenCard => !cards.find(card => writtenCard.team === card.team && writtenCard.opponent === card.opponent))
    try {
        if (removingCards.length) await mongo.db("League").collection("result").deleteMany({ _id: { $in: removingCards.map(removingCard => removingCard._id) } })
    } catch (e) {
        setMessages("データベースからの中止試合の削除中にエラーが発生しました", true)
        return null
    }
    try {
        if (writingCards.length) {
            const result = await functions.insertDocuments(writingCards)
            setMessages(`${result.insertedIds?.length}件のデータのデータベースへの書き込みが完了しました`)
            return null
        }
    } catch (e) {
        setMessages("データベースへ書き込めなかったデータがあります。再度取込してください", true)
        return null
    }
    setMessages("データベースへの書き込みが完了しました")
}



/*const getOpeningDate = () => {
    const today = new Date()
    const year = today.getFullYear() - (today.getMonth() < 3 ? 1 : 0)
    return new Date(year, 3, 1)
}*/

const Table = () => {
    const app = useRealmApp()
    const mongo = app.currentUser?.mongoClient("mongodb-atlas")
    const functions = app.currentUser?.functions
    const [processing, setProcessing] = useState(false)
    const [date, setDate] = useState<Date|undefined>(JSTNextSunday())
    const [file, setFile] = useState<File | undefined>()
    const [filename, setFilename] = useState("ファイルを選択してください")
    const [message, setMessage] = useState("")
    const error = useRef<string[]>([])
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const setMessages = (context: string, isError: boolean = false, add: boolean = false) => {
        if (!isError) setMessage(context);
        else {
            error.current = (add ? [...error.current, context] : [context]);
            setErrorMessages(error.current)
        }
    }
    const handleFileChoose = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.currentTarget.files?.[0]
        if (!selectedFile) return null
        if (file !== selectedFile){
            setFile(selectedFile)
            setMessage("ファイルが選択されました")
            setFilename(selectedFile?.name.replace(/.*[/\\]/, '') || "")
        }
    }
    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        if (processing) return null
        if (!mongo || !functions) {
            setMessage("データベースに接続されていません")
            return null
        }
        if (!file) {
            setMessage("ファイルが選択されていません")
            return null
        }
        if (!date) {
            setMessage("日付が選択されていません")
            return null
        }
        setProcessing(true)
        setMessage("集計しています")
        error.current = []
        switch (e.currentTarget.name) {
            case "request": await writeRequestFile(date, file, functions, mongo, setMessages); break;
            case "match": await readMatchFile(date, file, functions, mongo, setMessages); break;
            case "result": await writeResultFile(date, file, functions, mongo, setMessages); break;
            case "race": await writeSummaryFile(file, mongo, setMessages); break;
            case "lastrace-score": await writeLastSummaryFile(file, mongo, setMessages); break;
            case "lastrace-request": await writeLastSummaryFile(file, mongo, setMessages, true); break;
            default:
        }
        setProcessing(false)
    }
    return <div className="p-2 grid grid-cols-1 gap-2">
            <div className="text-theme-800 text-lg p-2 font-bold">集計操作</div>
            <div className="w-96"><DatePicker label="日程(日曜基準)" name="processingDate" value={date} setValue={setDate} enableHistory /></div>
            <label className={"w-96 file " + (processing ? "text-gray-400 bg-gray-100 " : "")}><div>{filename}</div>{/* Label is clickable and file chooser appear just by wrapping file type input */}
                <input type="file" id="file" aria-label="File browser" onChange={handleFileChoose} disabled={processing} />
                <span className="file-custom"></span>
            </label>
            <div className="flex">
                <button name="request" className={ButtonClass} onClick={handleClick} disabled={processing}>参加集計</button>
                <button name="match" className={ButtonClass} onClick={handleClick} disabled={processing}>カード取込</button>
                <button name="result" className={ButtonClass} onClick={handleClick} disabled={processing}>スコア集計</button>
                <button name="race" className={ButtonClass} onClick={handleClick} disabled={processing}>順位集計</button>
            </div>
            <div className="flex">
                <button name="lastrace-score" className={ButtonClass.replace("w-28", "w-56")} onClick={handleClick} disabled={processing}>最終順位集計(スコア記載)</button>
                <button name="lastrace-request" className={ButtonClass.replace("w-28", "w-56")} onClick={handleClick} disabled={processing}>最終順位集計(希望数記載)</button>
            </div>
            <div className="text-red-700">{errorMessages.map((v, i) => <div key={i}>{v}</div>)}</div>
            <div className="">{message}</div>
        </div>
}


export default Table