
import { Credentials } from "realm-web";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { applyActionCode, checkActionCode, confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from "firebase/auth";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRealmApp } from "RealmApp";
import { ButtonClass } from "utils";
import Loading from "./Loading";
import { PasswordInput } from "./uiparts";

const Verify = () => {
    const app = useRealmApp()
    const navigate = useNavigate()
    const [searchParams, ] = useSearchParams();
    const [processing, setProcessing] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [mode, setMode] = useState(searchParams.get('mode') || "");
    const actionCode = searchParams.get('oobCode') || "";
//    const continueUrl = searchParams.get('continueUrl') || "";
//    const lang = searchParams.get('lang') || 'en';

    const handleChange = (e:ChangeEvent<HTMLInputElement>|MouseEvent<HTMLButtonElement>) => {
        setMessage("新しいパスワードを入力してください")
        setError("")
        if (e.target instanceof HTMLInputElement) {
            if (e.target.name === "password") setPassword(e.target.value)
            if (e.target.name === "repeatPassword") setRepeatPassword(e.target.value)
        } else if (e.target instanceof HTMLButtonElement) {
            if (e.target.name === "change") resetPassword()
            if (e.target.name === "log") {
                navigate("/team")
                if (app.currentUser) app.logOut()
            }
        }
    }

    const resetPassword = async () => {
//        try {
            if (password !== repeatPassword) setError("再入力パスワードが一致しません。")
            else if (password.length < 8) setError("パスワードは8文字以上必要です。")
            else if (!actionCode) setError("無効または期限切れのコードが指定されています。再度パスワードをリセットしてください")
            else {
                const email = await verifyPasswordResetCode(app.auth, actionCode)
                await confirmPasswordReset(app.auth, actionCode, password)
                setMessage("パスワードを更新しました。ログインしています")
                await signInWithEmailAndPassword(app.auth, email, password);
                const token = (await app.auth.currentUser?.getIdToken()) || "";
                await app.logIn(Credentials.jwt(token));
                          navigate("/team")
            }
//        } catch (error) {
//            setError(`無効または期限切れのコードが指定されています。再度パスワードをリセットしてください:${actionCode}`)
//        };
    }

    const handleRecoverEmail = async () => {
        setProcessing(true)
        let restoredEmail = null;
        try {
            const info = await checkActionCode(app.auth, actionCode)
            restoredEmail = info['data']['email'];
            if (!restoredEmail) {
                setError("無効なコードです")
                setProcessing(false)
                return null
            }
            await applyActionCode(app.auth, actionCode);
            setMessage("メールアドレスを復元しました")
            setMode("finish")
        } catch (error) {
            setError("無効なコードです")
        };
        setProcessing(false)
    }

    const handleVerifyEmail = async () => {
        setProcessing(true)
        try {
            await applyActionCode(app.auth, actionCode)
            setMessage("メールアドレスが認証できました。ログインしてサービスを利用できます。既にログインしている場合は一度ログアウトしてください")
            setMode("finish")
        } catch (error) {
            setError("コードが無効か、期限が切れています。再度認証メールを送信してください")
        };
        setProcessing(false)
    }
    useEffect(() => {
        switch (mode) {
            case 'resetPassword':
                setMessage("新しいパスワードを入力してください")
                break;
            case 'recoverEmail':
                handleRecoverEmail();
                break;
            case 'verifyEmail':
                handleVerifyEmail();
                break;
            default:
                setMessage("処理内容がありません")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return <div className="h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        {processing && <Loading modal />}
        <div className="max-w-md w-full space-y-8">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">認証</h2>
            <div className="rounded-md shadow-sm -space-y-px min-h-44">
                {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                {mode === "resetPassword" && <>
                <div>
                    <label htmlFor="password" className="sr-only">
                        パスワード
                    </label>
                    <PasswordInput mode="top" value={password} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="repeatPassword" className="sr-only">
                        パスワード（再入力）
                    </label>
                    <input
                        id="repeatPassword"
                        name="repeatPassword"
                        type="password"
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                        placeholder="パスワード（再入力）"
                        value={repeatPassword}
                        onChange={handleChange}
                    />
                </div></>}
                <div className="p-2 flex justify-center space-x-2">
                {mode === "resetPassword" && <button name="change" className={ButtonClass} type="button" onClick={handleChange}><span className="flex items-center pr-1">
                    <CheckCircleIcon className="h-5 w-5 text-theme-100 group-hover:text-theme-400" aria-hidden="true" />
                </span>確定</button>}
                {mode !== "resetPassword" && <button name="log" className={ButtonClass} type="button" onClick={handleChange}>{app.currentUser ? "ログアウト" : "ログイン"}</button>}
                </div>
            </div>
        </div>
    </div>
}

export default Verify