import { MouseEvent, useState } from "react";

import { properties as requestView } from "LeagueApp/data_sources/mongodb-atlas/League/requestView/schema.json";
import {
  DataList,
  ListItem,
  EditLinkTd,
  FilterSelect,
  FilterInput,
} from "Components/List";
import { useAppContext } from "contexts/AppContext";
import options from "contexts/options.json";
import DatePicker from "Components/datepicker";
import { setJSTHour } from "contexts/dateUtils";
const location = "/admin/request";

const getTitle = (obj: {
  bsonType?: string;
  title?: string;
  description?: string;
}) => obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0] || "";
const heads: { [key: string]: string }[] = [
  { text: "チームID", field: "teamId" },
  { text: "所属", field: "team.league" },
  { text: "チーム名", field: "team.name" },
  { text: "所在地", field: "team.place" },
  { text: getTitle(requestView.date), field: "date" },
  { text: getTitle(requestView.double), field: "double" },
  { text: "状況", field: "status" },
  { text: getTitle(requestView.modifiedAt), field: "modifiedAt" },
  { text: getTitle(requestView.remarks), field: "remarks" },
  { text: "", field: "" },
];

export const baseFilter = {
  league: "",
  group: "",
  team: { name: "", repName: { surnameKana: "" } },
};

const Filter = (key:string, count: number, first:KV, last:KV, handleDownload: () => void) => {
  const collectionName = "requestView"
  const appContext = useAppContext()
  const filter = appContext.state[collectionName]?.filter || {}
  const [team, setTeam] = useState<string>(filter.team?.name?.$regex || "")
  const [teamId, setTeamId] = useState<string>(filter.team?.teamId || "")
  const updateFilter = (key: string, value: any) => {
    const keys = key.split(".")
    if (keys[1]) {
      appContext.dispatch({
        [collectionName]: { ...appContext.state[collectionName], filter: { ...filter, [keys[0]]: { ...filter[keys[0]] || {}, [keys[1]]: value} , }, page:1 }
      });
    } else {
      console.log([key, value])
      appContext.dispatch({
        [collectionName]: { ...appContext.state[collectionName], filter: { ...filter, [key]: value, }, page:1 }
      });
    }
  }

  const handleBlur = (e: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLInputElement, Element> | React.KeyboardEvent<HTMLInputElement>) => {
    let query:KV = {...filter};
    if (e.target instanceof HTMLInputElement){
        if (e.target.name === "team") {
          query.team = { ...filter.team || {}}
          query.team.name = { $regex: e.target.value }; //!!!Pagination Use Mongo, not GraphQL. So Regex is OK
        } else if (e.target.name === "teamId") {
          query.team = { ...filter.team || {}}
          query.team.teamId = parseInt(e.target.value) || 0;
        }
    }
    appContext.dispatch({ [collectionName]: { ...appContext.state[collectionName], filter: query, page:1 }});
  };
  return (
    <>
      {filter && ( <>
          <DatePicker
              name="date"
              span={1}
              holder="試合日"
              value={filter.date}
              setValue={(v) => updateFilter("date", v ? setJSTHour(v, 0) : v)}
              enableHistory
              notinform
          />
          <FilterSelect
            label="階級"
            name="league"
            value={filter.team?.league || ""}
            option={options.league}
            setState={(v) => updateFilter("team.league", v)}
            onBlur={() => {}}
          />
          <FilterSelect
            label="グループ"
            name="group"
            value={filter.team?.group || ""}
            option={options.group}
            setState={(v) => updateFilter("team.group", v)}
            onBlur={() => {}}
          />
          <FilterInput
            label="チーム名"
            name="team"
            value={team}
            setState={setTeam}
            onBlur={handleBlur}
          />
          <FilterInput
            label="チームID"
            name="teamId"
            value={teamId}
            setState={setTeamId}
            onBlur={handleBlur}
          />
          <FilterSelect
            label="状況"
            name="status"
            value={filter.status || ""}
            option={options.reqStatus}
            setState={(v) => updateFilter("status", v)}
            onBlur={() => {}}
          />
        </>
      )}
    </>
  );
};

const row = ({
  datum,
  clickDelete,
}: {
  datum: KV;
  clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <>
    <ListItem data={[datum.team.teamId]} left />
    <ListItem data={[datum.team.league]} left />
    <ListItem data={[datum.team.name]} left />
    <ListItem data={[datum.team.place]} left />
    <ListItem data={[datum.date]} date />
    <ListItem data={[datum.double ? "ダブルヘッダー" : ""]} badge />
    <ListItem data={[options.reqStatus[datum.status]]} badge />
    <ListItem data={[datum.modifiedAt || datum.createdAt]} date />
    <ListItem data={[datum.remarks ? datum.remarks.slice(0, 15) : ""]} left />
    <EditLinkTd
      location={location}
      id={datum._id}
      onClickDelete={clickDelete}
    />
  </>
);

const List = () => {
  return (
    <DataList
      collection="requestView"
      location={location}
      sort="date"
      heads={heads}
      filter={Filter}
      row={row}
      pagination
    />
  );
};

export default List;
